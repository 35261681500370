<template>
    <article class="c-navigation relative z-0">
        <div ref="megaMenuAnchor" v-syncBodylockGap class="c-navigation-anchor relative z-1 bg-blue-500 oprema:bg-opremayellow-500 text-white oprema:text-black"
             :style="navigationStyle"
             @mouseleave="toggleMegaMenu(false)"
             @mouseenter="cancelMegaMenuClosing()">
            <div class="o-chrome-container">
                <section class="level-1-menu">
                    <MainNavigationSlider :menus="menus"
                                          :active-menu="activeMenu"
                                          :mega-menu-shown="megaMenuShown"
                                          @activeMenuChanged="changeMegaMenuDebounce"
                                          @cancelMegaMenuNavigation="cancelMegaMenuNavigation"
                                          @closeMegaMenu="toggleMegaMenu(false)"/>
                </section>
            </div>
        </div>
        <transition name="mega-menu">
            <div v-if="megaMenuShown" ref="megaMenuContainer" class="mega-menu bg-white overflow-y-auto relative"
                 :style="megaMenuStyle()"
                 @mouseleave="toggleMegaMenu(false)"
                 @mouseenter="toggleMegaMenu(true)">
                <div class="o-chrome-container bg-white">
                    <section v-if="activeMenu" class="py-20 flex flex-row flex-wrap">
                        <div v-for="(menu, mi) in activeMenu.children" :key="mi" class="sm:w-1/4 xl:w-1/5 mb-20 pr-20">
                            <CultureAwareRouterLink :to="menu.url" class="sm:text-12 md:text-14 lg:text-16 font-semi-bold mb-10 hover:underline block truncate"
                                                    @click.native="toggleMegaMenu(false)">
                                <span :title="menu.name">{{ menu.name }}</span>
                            </CultureAwareRouterLink>
                            <ReadMore v-if="menu.children && menu.children.length > 0" :key="menu.name" tag="ul" :min-items="12"
                                      :animation-time-mod="0"
                                      button-class="text-gray-900 sm:text-10 md:text-12 lg:text-13 cursor-pointer hover:font-medium pt-6">
                                <li v-for="(child, ci) in menu.children" :key="ci">
                                    <CultureAwareRouterLink :to="child.url" class="leading-double sm:text-10 md:text-12 lg:text-13 font-regular hover:underline block truncate"
                                                            @click.native="toggleMegaMenu(false)">
                                        <span :title="child.name">{{ child.name }}</span>
                                    </CultureAwareRouterLink>
                                </li>
                                <div slot="collapse">
                                    {{ $dictionary.get('Generic.ReadLess') }}
                                </div>
                                <div slot="expand">
                                    {{ $dictionary.get('Generic.ReadMore') }}
                                </div>
                            </ReadMore>
                        </div>
                    </section>
                </div>
                <button class="overlay__close" aria-label="Close"
                        @click="close()">
                    <CIcon name="close" class="close-cross" height="12" width="12"/>
                </button>
            </div>
        </transition>
    </article>
</template>

<script lang="ts">
import { StyleValue } from 'vue';
import { Vue, Component } from 'vue-property-decorator';
import megaMenuService from '@/project/shared/megaMenu.service';
import { MenuItem } from '@/types/apiServerContract';
import MainNavigationSlider from './MainNavigationSlider.vue';
import ReadMore from '@/core/ui-components/ReadMore.vue';
import scrollService from '@/core/scroll/scroll.service';
import escEventService from '@/core/esc-event.service';
import debounce from 'lodash-es/debounce';
import themeService from '@/project/app/themes/theme.service';

// Delay in ms controlling megamenu, so it won't be triggered too eager
const SHOW_DELAY = 50;
const CLOSE_DELAY = 100;
const CHANGE_DELAY = 350;

@Component({
    components: {
        MainNavigationSlider,
        ReadMore
    }
})
export default class MainNavigation extends Vue {
    megaMenuShown = false;
    menus: readonly MenuItem[] | null = [];
    activeMenu: MenuItem | null = null;

    openMegaMenuDebounce = debounce(this.open, SHOW_DELAY);
    closeMegaMenuDebounce = debounce(this.close, CLOSE_DELAY);
    changeMegaMenuDebounce = debounce(this.changeMegaMenu, CHANGE_DELAY);

    changeMegaMenu(menu: MenuItem): void {
        this.activeMenu = menu;

        // If mega menu is hidden, no reason to debounce, show it instantly
        if (!this.megaMenuShown) {
            this.open();
        }
    }

    cancelMegaMenuNavigation(): void {
        this.changeMegaMenuDebounce.cancel();
    }

    cancelMegaMenuClosing(): void {
        this.closeMegaMenuDebounce.cancel();
    }

    toggleMegaMenu(show: boolean): void {
        if (show) {
            this.closeMegaMenuDebounce.cancel();
            this.openMegaMenuDebounce();
        } else {
            this.openMegaMenuDebounce.cancel();
            this.closeMegaMenuDebounce();
        }
    }

    open(): void {
        if (this.megaMenuShown) return;

        this.megaMenuShown = true;
        this.$emit('megaMenuShown', true);

        // megaMenuContainer needs to be in DOM first, therefore nextTick
        this.$nextTick(() => {
            if (this.$refs.megaMenuContainer) {
                    scrollService.disableBodyScroll(this.$refs.megaMenuContainer)
            }
        });

        escEventService.register(this.close);
    }

    close(): void {
        if (!this.megaMenuShown) return;

        if (this.$refs.megaMenuContainer) {
            scrollService.enableBodyScroll(this.$refs.megaMenuContainer);
        }

        this.megaMenuShown = false;
        this.$emit('megaMenuShown', false);

        escEventService.unregister(this.close);
    }

    async getMegaMenuData(): Promise<void> {
        this.menus = await megaMenuService.getMegaMenu();
    }

    megaMenuStyle(): StyleValue {
        const top = this.$refs.megaMenuAnchor?.getBoundingClientRect().bottom ?? 0;
        return ({
            maxHeight: `calc(100vh - ${top}px)`
        });
    }

    created() {
        this.getMegaMenuData();
    }

    $refs!: {
        megaMenuContainer: HTMLElement;
        megaMenuAnchor: HTMLElement;
    };

    get navigationStyle(): StyleValue {
        return {
            ...(themeService.theme?.menuColor && { backgroundColor: themeService.theme.menuColor }),
            ...(themeService.theme?.menuColor && { color: themeService.getColor(themeService.theme.menuColor) })
        };
    }
}
</script>

<style lang="less" scoped>
    .mega-menu {
        @apply border-blue-500 oprema:border-black;
        border-bottom-width: 4px;

        .top-header-fixed & {
            max-height: calc(100vh - 13.4rem);
        }
    }

    .level-1-menu {
        height: 6rem;
    }

    .mega-menu-enter-active {
        transition: transform 250ms ease-out;
    }

    .mega-menu-leave-active {
        transition: transform 200ms ease-in;
    }

    .mega-menu-enter,
    .mega-menu-leave-to {
        transform: translate3d(0, -100%, 0);
    }

    .overlay__close {
        position: absolute;
        top: 20px;
        right: 20px;
    }

</style>
