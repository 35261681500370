<template>
    <div class="relative">
        <CultureAwareRouterLink class="py-10 px-5"
                                :to="{ name: PageName.BASKET }">
            <div class="mini-basket rounded-full relative flex items-center justify-center"
                 :class="{ 'mini-basket__heartbeat': animate,
                           'text-white': isInWhiteTheme ,
                           'theme-text-linkcolor md:text-white md:bg-green-500': !isInWhiteTheme }"
                 @animationend="animate = false">
                <CIcon class="mini-basket__basket-icon relative" name="basket"/>
            </div>
            <div v-if="noOfBasketItems > 0"
                 :class="{ 'mini-basket__pulse': animate,
                           'bg-white text-black': isInWhiteTheme,
                           'bg-green-500 oprema:bg-opremablue-500 text-white': !isInWhiteTheme }"
                 class="mini-basket__items-count absolute rounded-full flex items-center justify-center">
                <span class="inline-block font-bold text-11 md:text-12 leading-normal">{{ noOfBasketItems }}</span>
                <span class="sr-only">{{ $dictionary.get('Checkout.BasketLink') }}</span>
            </div>
        </CultureAwareRouterLink>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, watch} from 'vue';
import basketService from '@/project/basket/basket.service';
import { PageName } from '@/router/routes/constants';
import themeService from '@/project/app/themes/theme.service';

const animate = ref(false);

const noOfBasketItems = computed(() => {
    return basketService.noOfBasketItems();
});
watch(() => noOfBasketItems.value, () => {
    if (basketService.basket) {
        animate.value = true;
    }
});

const isInWhiteTheme = computed(() => {
    return themeService.isInWhiteTheme;
});
</script>

<style lang="less">
    .mini-basket {
        animation-duration: 1.3s;
        transition: transform 200ms ease-in-out, color 500ms ease-in-out, background-color 500ms ease-in-out;

        &:hover {
            transform: scale(1.1);
        }
    }

    .mini-basket__basket-icon {
        left: -2px;
        height: 2.4rem;
        width: 2.6rem;

        .top-header-fixed & {
            height: 2rem;
            width: 2.1rem;
        }
    }

    .mini-basket__items-count {
        transition: color 500ms ease-in-out, background-color 500ms ease-in-out;
        height: 2.1rem;
        min-width: 2.1rem;
        padding: 0 3px;
        top: 0rem;
        left: 2.3rem;

        .top-header-fixed & {
            top: -0.3rem;
            left: 2.8rem;
        }
    }

    .mini-basket__heartbeat {
        animation-name: heartBeat;
        animation-duration: 1.3s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: both;
    }

    .mini-basket__pulse {
        animation-name: pulse;
        animation-duration: 1.2s;
    }
    @screen md {
        .mini-basket {
            width: 4.8rem;
            height: 4.8rem;
            body[data-theme="oprema"] & {
                @apply bg-opremayellow-500 text-opremablue-500;
            }

            .top-header-fixed & {
                width: 3.8rem;
                height: 3.8rem;
            }
        }
        .mini-basket__basket-icon {
            height: 2.5rem;
            width: 2.6rem;
        }
        .mini-basket__items-count {
            height: 2.3rem;
            min-width: 2.3rem;
            padding: 0 5px;
            top: 0.5rem;
            left: 3.5rem;
            .top-header-fixed & {
                top: 0.5rem;
                left: 2.8rem;
            }
        }
    }

    @keyframes heartBeat {
        0% {
            transform: scale(1);
        }

        14% {
            transform: scale(1.1);
        }

        28% {
            transform: scale(1);
        }

        42% {
            transform: scale(1.1);
        }

        70% {
            transform: scale(1);
        }
    }

    @keyframes pulse {
        from {
            transform: scale3d(1, 1, 1);
        }

        50% {
            transform: scale3d(1.1, 1.1, 1.1);
        }

        to {
            transform: scale3d(1, 1, 1);
        }
    }

</style>
