<template>
    <AdnuntiusPixels
        :rendered-url="content.renderedPixelUrl"
        :visible-url="content.visiblePixelUrl"
        :view-url="content.viewPixelUrl"
        :click-url="content.clickPixelUrl"
        always-visible
        class="mx-3 sm:mx-10 first:ml-0 last:mr-0">
        <template #default="{ click }">
            <CultureAwareRouterLink
                :to="content.brandUrl"
                class="c-brandlogo" target="_top" :style="brandThemeStyle" @click.native="click">
                <img :src="content.image.url"
                     :alt="content.name"
                     class="c-brandlogo-image c-brandlogo-image__gray"
                     :class="imageCssClass" :style="`aspect-ratio: ${content.image.width / content.image.height}`">
                <span class="sr-only">{{ $dictionary.get('BrandHero.BrandLogo.SeeAllProductsFrom') }} {{ content.name }}</span>
            </CultureAwareRouterLink>
        </template>
    </AdnuntiusPixels>
</template>

<script lang="ts">
import { Prop, Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import AdnuntiusPixels from '@/project/tracking/adnuntius/AdnuntiusPixels.vue';
import { BrandLogoViewModel } from '@/types/apiServerContract';
import { PageName } from '@/router/routes/constants';
import themeService from '@/project/app/themes/theme.service';
import { StyleValue } from 'vue';

@Component({
    components: { AdnuntiusPixels }
})
export default class BrandLogo extends Vue {
    @Prop({
        type: Object
    }) content!: BrandLogoViewModel;

    get imageCssClass() : string {
        const isTall = this.content.image.width < this.content.image.height;
        const isSquare = this.content.image.width === this.content.image.height;

        return isTall || isSquare ? 'c-brandlogo-image--max-h' : 'c-brandlogo-image--max-w';
    }

    get brandPageName() {
        return PageName.BRAND;
    }

    get brandThemeStyle(): StyleValue {
        const isInWhiteTheme = themeService.isInWhiteTheme;

        return {
            ...(isInWhiteTheme && { filter: 'brightness(0) invert(1)' })
        };
    }
}
</script>

<style lang="less">
    .c-brandlogo {
        &:hover {
            .c-brandlogo-image {
                filter: grayscale(0);
                opacity: 1;
            }
        }
    }

    .c-brandlogo-image {
        &.c-brandlogo-image__gray {
            filter: grayscale(1);
            opacity: 0.6;
            transition: filter, opacity .5s ease-out;
        }
        max-height: 2.5rem;
        min-width: 2.5rem;

        &.c-brandlogo-image--max-w {
            max-width: 6rem;
            min-width: 6rem;
            width: 100%;
        }
    }

    @screen sm {
        .c-brandlogo-image {
            &.c-brandlogo-image--max-w {
                max-width: 7.5rem;
                min-width: 7rem;
            }
        }
    }
    @screen md {
        .c-brandlogo-image {
            max-height: 3.5rem;
            min-width: 3.5rem;
            &.c-brandlogo-image--max-w {
                max-width: 7.5rem;
                min-width: 7rem;
                max-height: 5rem;
                width: 100%;
            }
        }
    }
</style>
