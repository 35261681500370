<template>
    <transition appear
                enter-active-class="animated fadeIn u-anim-dur-500"
                leave-active-class="animated fadeOut u-anim-dur-500">
        <article v-if="messages.length" class="fixed top-0 w-full z-errorToaster">
            <div v-for="message in messages" :key="message.errorId || message.message"
                 class="text-white p-30 font-medium text-16 flex w-full items-center justify-between border border-white"
                 :class="getBgColor(message.messageType)">
                <p>
                    {{ getMessage(message.message) }}
                    <template v-if="message.errorId">
                        [{{ message.errorId }}]
                    </template>
                </p>
                <button class="pl-30" type="button" @click="clearMessage(message)">
                    <CIcon name="close" height="18" width="18" class="text-white"/>
                </button>
            </div>
        </article>
    </transition>
</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { ClientMessage, ClientMessageType, ClientMessageWithError, GeneralErrorEventKey } from '@/core/messages/types';
import bus from '@/core/bus';
import dictionaryService from '@/core/translation/dictionary.service';

const AUTO_KILL_TIME = 10000; // ms.

@Component
export default class ErrorToaster extends Vue {
    messages: ClientMessage[] = [];

    created() {
        bus.on(GeneralErrorEventKey, this.addMessages);
    }

    getBgColor(messageType: ClientMessageType): string {
        switch (messageType) {
        case ClientMessageType.Info:
            return 'bg-green-500';
        case ClientMessageType.Warning:
            return 'bg-yellow-500';
        case ClientMessageType.Error:
        default:
            return 'bg-red-500';
        }
    }

    getMessage(msgOrLabel: string): string {
        if (dictionaryService.exists(msgOrLabel)) {
            return dictionaryService.get(msgOrLabel);
        }
        return msgOrLabel;
    }

    addMessages(clientMessages: ClientMessageWithError): void {
        clientMessages.messages.forEach(msg => {
            msg.errorId = clientMessages.errorId;
            setTimeout(() => this.clearMessage(msg), AUTO_KILL_TIME);
        });

        this.messages = this.messages.concat(clientMessages.messages);
    }

    clearMessage(msg: ClientMessage): void {
        this.messages = this.messages.filter(m => m !== msg);
    }
}
</script>
