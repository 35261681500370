<template>
    <validation-provider v-slot="{ errors, classes }" :rules="rules" tag="div">
        <div class="relative h-40" :class="{ hasValue: !!value }">
            <div class="input-number inline-flex items-center rounded-full h-full bg-gray-400">
                <MinusCancel
                    :mode="editMode ? 'cancel' : 'minus'"
                    class="cursor-pointer minus-cancel"
                    :class="{ 'pointer-events-none': disabled, 'disabled': minusDisabled }"
                    tabindex="0"
                    @minus-click="deltaAdjustQuantity(-1)"
                    @cancel-click="done"/>
                <input
                    v-if="!disabled"
                    :id="id"
                    ref="inputElem"
                    v-model="internalValue"
                    v-autofocus="autofocus"
                    v-prohibit-zoom
                    v-whole-numbers-only
                    type="tel"
                    pattern="[0-9]{1,3}"
                    :min="minValue"
                    :max="maxValue"
                    class="input-field flex-grow flex-shrink font-semi-bold text-center min-w-0 rounded-full outline-none bg-gray-400"
                    :class="classes"
                    @focus="inputFocus"
                    @blur="inputBlur"
                    @keydown.enter.esc="inputEnterEscKeyUp">
                <div
                    v-else-if="disabled"
                    class="disabled input-field w-40 border border-grey-dark p-5 flex-grow flex-shrink text-center min-w-0">
                    {{ internalValue }}
                </div>
                <PlusAccept
                    :mode="editMode ? 'accept' : 'plus'"
                    class="cursor-pointer plus-accept outline-none"
                    :class="{'pointer-events-none disabled': disabled || isAtMax || plusDisabled }"
                    tabindex="0"
                    @plus-click="deltaAdjustQuantity(1)"
                    @accept-click="setQuantity"/>
            </div>
        </div>
        <template v-if="showErrors && errors && errors.length">
            <ol>
                <li v-for="error in errors" :key="error" class="text-red-500 text-11 font-medium px-20 pb-5 pt-3">
                    {{ error }}
                </li>
            </ol>
        </template>
    </validation-provider>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import PlusAccept from '@/project/basket/PlusAccept.vue';
import MinusCancel from '@/project/basket/MinusCancel.vue';

@Component({
    components: { PlusAccept, MinusCancel }
})
export default class InputNumber extends Vue {
    @Prop({ type: Number, required: true })
    value!: number;

    @Prop({ type: String, required: false, default: '' })
    label!: string;

    @Prop({ type: String, required: true })
    name!: string;

    @Prop({ type: [String, Object], required: false, default: '' })
    rules!: string | object;

    @Prop({ type: Boolean, required: false, default: false })
    autofocus!: boolean;

    @Prop({ type: Boolean, required: false, default: true })
    showErrors!: boolean;

    @Prop({ type: Number, required: false, default: 1 })
    minValue!: number;

    @Prop({ type: Number, required: false, default: Infinity })
    maxValue!: number;

    @Prop({ type: Boolean, default: false })
    disabled!: boolean;

    editMode = false;
    internalValue: number = 0;

    get minusDisabled(): boolean {
        return this.disabled || (this.value <= this.minValue && !this.editMode);
    }

    get plusDisabled(): boolean {
        return this.disabled || (this.value >= this.maxValue && !this.editMode);
    }

    inputFocus() {
        this.editMode = true;
        this.$refs.inputElem.select();
    }

    inputBlur() {
        // Need to delay until a possible click on accept/cancel arrives. This comes after blur from inputfield...
        setTimeout(() => {
            if (this.editMode) {
                // All blur events exept cancel will set quantity
                this.setQuantity();
                this.done();
            }
        }, 250);
    }

    inputEnterEscKeyUp(event) {
        if (event.key === 'Enter') {
            this.setQuantity();
        }
        this.$refs.inputElem.blur();
        this.done();
    }

    done() {
        this.editMode = false;
    }

    deltaAdjustQuantity(delta: number) {
        this.setQuantity(delta);
    }

    isAllowedValue(val: number): boolean {
        return val >= this.minValue && val <= this.maxValue;
    }

    setQuantity(delta: number = 0) {
        const inputValue = this.internalValue || this.value || this.minValue;
        let value = parseInt(inputValue + '', 10) + delta;
        if (!this.isAllowedValue(value)) {
            value = value < this.minValue ? this.minValue : value > this.maxValue ? this.maxValue : this.value;
        }
        this.internalValue = value;
        this.$emit('input', value);
        this.done();
    }

    get id() {
        return this.name + Math.random();
    }

    get hasValue(): boolean {
        return this.value != null;
    }

    get isAtMax() {
        return this.value === this.maxValue;
    }

    created() {
        this.internalValue = this.value;
    }

    $refs!: {
        // Just to have type...
        inputElem: HTMLInputElement;
    };
}
</script>

<style lang="less" scoped>
.input-number {

    input {
        width: 5rem;
        @apply h-full rounded-full text-center placeholder-gray-825;
    }

    .input-field {
        max-width: 5rem;
        margin: 0;
        border-image: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0)) 0 100%;
    }

    .plus-accept,
    .minus-cancel {
        margin: 0 0.6rem;

        &.disabled {
            cursor: default;
            opacity: 0.5;
        }
    }
}
</style>
