<template>
    <header class="z-header absolute top-0 inset-x-0">
        <ScrollTrigger :options="scrollTriggerOptions">
            <section v-if="showBrands" class="h-40 relative z-3">
                <BrandHeader/>
            </section>
            <article class="fixable-header">
                <div class="o-chrome-container bg-white relative z-3" :style="topHeaderStyle">
                    <div v-sync-bodylock-gap class="h-60 flex justify-between items-center relative">
                        <CultureAwareRouterLink to="/" :title="$dictionary.get('FrontPage.Home')" class="mr-auto">
                            <CIcon name="logo"
                                   :original="isOprema ? 'true' : undefined"
                                   class="c-mobile-pageheader--logo"
                                   :class="{ 'text-white': isInWhiteTheme, 'text-blue-500 oprema:text-black': !isInWhiteTheme }"/>
                        </CultureAwareRouterLink>
                        <div class="flex z-1" :class="{ 'text-white': isInWhiteTheme, 'text-blue-500 oprema:text-black': !isInWhiteTheme }">
                            <button class="flex items-center mr-15 py-10 px-5" @click="clickShowSearch">
                                <CIcon name="search" width="24" height="24"/>
                                <span class="sr-only">{{ $dictionary.get('Search.MobileSearchFieldHeader') }}</span>
                            </button>
                            <CultureAwareRouterLink v-if="showBecomeACustomer"
                                                    data-tracking-name="becomeACustomerPage"
                                                    class="mr-15 py-10 px-5"
                                                    :to="becomeACustomerPage" :class="{ 'text-white': isInWhiteTheme, 'text-blue-500 oprema:text-black': !isInWhiteTheme }">
                                <CIcon name="become-a-customer" width="27" height="27" :fill="null"/>
                                <span class="sr-only">{{ $dictionary.get('Information.BecomeACustomer.HeaderButton') }}</span>
                            </CultureAwareRouterLink>
                            <MiniBasket v-if="isLoggedIn && $permission.canPlaceOrder()"/>
                            <button v-else-if="!isLoggedIn" class="flex items-center" @click="login">
                                <CIcon name="login" width="27" height="27"/>
                                <span class="sr-only">
                                    {{ $dictionary.get('Authentication.Login.LoginBtn') }}
                                </span>
                            </button>
                        </div>
                        <button class="burger-button z-1 relative py-10 px-5"
                                :class="{'ml-20': isLoggedIn, 'ml-15': !isLoggedIn, 'text-white': isInWhiteTheme, 'text-blue-500 oprema:text-black': !isInWhiteTheme }"
                                aria-label="Menu"
                                @touchend="showMobileMenu"
                                @click="showMobileMenu">
                            <CIcon name="burger" height="17" width="24"/>
                            <span class="sr-only">Menu</span>
                        </button>
                    </div>
                </div>
                <article ref="search-bar"
                         :style="searchElementStyle">
                    <SearchCtrl v-sync-bodylock-gap
                                class="w-full"
                                :section-class="searchCtrlSectionClass"
                                :section-styling="searchCtrlSectionStyling"
                                :show-search-field="searchShown"
                                :anchor-element="searchOverlayAnchorElement"
                                @openMobileSearch="openMobileSearch"
                                @closeMobileSearch="closeMobileSearch"/>
                </article>
            </article>
        </ScrollTrigger>

        <ImpersonationBanner/>
    </header>
</template>

<script lang="ts">
import Vue, { StyleValue } from 'vue';
import Component from 'vue-class-component';
import BrandHeader from '@/project/layout/page-header/BrandHeader.vue';
import ScrollTrigger, { ScrollTriggerOptions } from '@/core/scroll/ScrollTrigger.vue';
import SearchCtrl from '@/project/search/SearchCtrl.vue';
import MiniBasket from '@/project/basket/MiniBasket.vue';
import authService from '@/core/auth/auth.service';
import ImpersonationBanner from '@/project/shared/impersonation/ImpersonationBanner.vue';
import loginFormOverlayService from '@/project/modals/overlays/LoginFormOverlay.service';
import mobileMenuOverlayService from '@/project/modals/overlays/MobileMenuOverlay.service';
import themeService from '@/project/app/themes/theme.service';
import { hexToRgbValues } from '@/core/color/color.service';
import serverContextService from '@/core/serverContext.service';
import appStore from '@/core/app/app.store';
import urlHelperService from '@/core/urlHelper.service';

@Component({
    components: { MiniBasket, SearchCtrl, ScrollTrigger, BrandHeader, ImpersonationBanner }
})
export default class MobilePageHeader extends Vue {
    searchOverlayAnchorElement: Element | null = null;
    searchShown: boolean = false;

    mounted() {
        this.searchOverlayAnchorElement = this.$refs['search-bar'] as Element;
    }

    get isLoggedIn() {
        return authService.isLoggedIn();
    }

    login() {
        loginFormOverlayService.show('login');
    }

    showMobileMenu() {
        mobileMenuOverlayService.show();
    }

    get logoDimensions(): {width: string, height: string } {
        return {
            height: '60',
            width: serverContextService.isOprema ? '100' : '60'
        };
    }

    get isInWhiteTheme() {
        return themeService.isInWhiteTheme;
    }

    get topHeaderStyle(): StyleValue {
        const rgb = themeService.theme?.topColor && hexToRgbValues(themeService.theme.topColor);

        return {
            ...(rgb && { backgroundColor: `rgba(${rgb.red}, ${rgb.green}, ${rgb.blue}, ${themeService.takeOverOpacity})` }),
            ...(themeService.theme?.topColor && { borderColor: themeService.theme.topColor })
        };
    }

    get searchElementStyle(): StyleValue {
        return {
            backgroundColor: themeService.theme?.topColor,
            ...(themeService.theme?.topColor && { borderColor: themeService.theme.topColor })
        };
    }

    get isOprema() {
        return serverContextService?.isOprema;
    }

    get scrollTriggerOptions(): ScrollTriggerOptions {
        return this.showBrands
            ? { 40: 'header-fixed' }
            : { 1: 'header-fixed' };
    }

    get showBrands(): boolean {
        // not logged in on frontpage: false, else true
        if (this.isOnFrontpage && this.isLoggedIn === false) {
            return false;
        }
        return true;
    }

    get isOnFrontpage(): boolean {
        return appStore.isFrontPage;
    }

    get becomeACustomerPage() {
        return serverContextService?.settings?.becomeACustomerPage;
    }

    get showBecomeACustomer(): boolean {
        // Only show for users who has never been logged in - and only if page exists
        return ((!this.isLoggedIn && !authService.hasLoggedInBefore()) && !!this.becomeACustomerPage);
    }

    clickShowSearch() {
        if (this.searchShown) {
            urlHelperService.setTerm(''); // will make search overlay go away
            this.searchShown = false;
        } else {
            this.searchShown = true;
        }
    }

    closeMobileSearch() {
        this.searchShown = false;
    }

    openMobileSearch() {
        this.searchShown = true;
    }

    get searchCtrlSectionClass(): string {
        return `${this.isInWhiteTheme ? '' : 'bg-white'} px-20 u-trans u-trans-transform fixed left-0 right-0 top-0 z-3`;
    }

    get searchCtrlSectionStyling(): StyleValue {
        return this.isInWhiteTheme ? this.searchElementStyle : { };
    }
}
</script>

<style lang="less" scoped>
    .fixable-header {
        .header-fixed & {
            position: fixed;
            top: 0;
            width: 100%;
        }
    }
    /* purgecss ignore */
    .c-mobile-pageheader--logo {
        body[data-theme="EetRetail"] &,
        body[data-theme=""] & {
            width: 5.2rem;
            height: auto;
        }
        body[data-theme="oprema"] & {
            width: 10rem;
            height: auto;
            margin-top: 0.9rem;
        }
    }
    .burger-button:after {
        @import (reference) '../../../../styling/1-Tools/extend-clickable-area';
        .extend-clickable-area(-5px)
    }
</style>
