import Vue from 'vue';
import bus from '@/core/bus';
import { BODY_SCROLL_LOCKED } from './scroll.service';

interface HTMLElementWithFunc extends HTMLElement {
    bodyScrollLocked?: (gap: number) => void
}

Vue.directive('syncBodylockGap', {
    inserted(el: HTMLElement) {
        const customEl = el as HTMLElementWithFunc;
        customEl.bodyScrollLocked = (scrollbarGap: number) => {
            el.style.paddingRight = scrollbarGap + 'px';
        };
        bus.on(BODY_SCROLL_LOCKED, customEl.bodyScrollLocked);
    },
    unbind(el: HTMLElement) {
        const customEl = el as HTMLElementWithFunc;
        if (customEl?.bodyScrollLocked) {
            bus.off(BODY_SCROLL_LOCKED, customEl.bodyScrollLocked);
            delete customEl.bodyScrollLocked;
        }
    }
});
