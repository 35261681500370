<template>
    <div class="c-radiobutton">
        <input
            :id="id"
            type="radio"
            class="c-radiobutton__input sr-only"
            :name="name"
            :value="theValue"
            :checked="shouldBeChecked"
            v-bind="$attrs"
            @change="updateValue"
            v-on="listeners">
        <label class="c-radiobutton__label" :class="{ 'c-radiobutton__label--white': white }" :for="id">
            <CIcon v-if="hasIconBefore" :name="iconName" width="24" height="24"
                   aria-hidden="true" class="c-radiobutton__icon mt-auto mb-auto mr-10" :fill="true" :original="true"/>
            <span :class="labelClass">{{ label }}</span>
            <CIcon v-if="hasIconAfter" :name="iconName" width="24" height="24"
                   aria-hidden="true" class="c-radiobutton__icon absolute top-0 bottom-0 mt-auto mb-auto" :fill="true" :original="true"/>
        </label>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Model } from 'vue-property-decorator';

/*
Inspired by this: https://www.smashingmagazine.com/2017/08/creating-custom-inputs-vue-js/
 */
@Component
export default class InputRadio extends Vue {
    @Prop({
        type: String,
        default: ' '
    }) label!: string;

    @Prop(String) name!: string;
    @Prop(Boolean) white!: boolean;

    @Prop([String, Number, Boolean, Object]) theValue!: string | number | boolean | object;

    @Prop(String)
    icon!: string;

    @Prop({ type: Boolean, required: false, default: true })
    iconIsAfter!: boolean;

    @Prop(String)
    labelClass!: string;

    @Model('change')
    readonly modelValue!: string | number | boolean | object;

    get id(): string {
        return this.name + Math.random();
    }

    get iconName() {
        return this.icon?.toLowerCase();
    }

    get hasIconBefore(): boolean {
        return !!this.icon && !this.iconIsAfter;
    }

    get hasIconAfter(): boolean {
        return !!this.icon && this.iconIsAfter;
    }

    updateValue(e) {
        this.$emit('change', this.theValue);
    }

    get shouldBeChecked() {
        return this.modelValue === this.theValue;
    }

    get listeners() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { change, ...listeners } = this.$listeners;
        return listeners;
    }
}
</script>

<style lang="less" scoped>

    .c-radiobutton__input {
        position: absolute;
        width: 0;
        height: 0;
        overflow: hidden;
        z-index: -1;
        opacity: 0;

        &:focus ~ .c-radiobutton__label::before {
            border: 1px solid theme('colors.gray.700');
        }
    }

    .c-radiobutton__label {
        @apply relative inline-flex items-center cursor-pointer;
        padding-left: 35px;

        .c-radiobutton__input:disabled + & {
            @apply text-gray-700 cursor-default;
        }
    }

    .c-radiobutton__label::before,
    .c-radiobutton__label::after {
        content: '';
    }

    .c-radiobutton__label::before {
        width: 2rem;
        height: 2rem;
        left: 0;
        @apply bg-gray-400 rounded-full absolute;

        .c-radiobutton__input:disabled + & {
            @apply bg-gray-400;
        }
    }

    &.c-radiobutton__label {
        &--white::before {
            @apply bg-white;
        }
    }

    .c-radiobutton__label::after {
        position: absolute;
        width: 1rem;
        height: 1rem;
        top: 0;
        left: 0.5rem;
        bottom: 0;
        margin: auto 0;
        transition: opacity 150ms ease-in-out;
        @apply bg-blue-500 oprema:bg-opremablue-500 rounded-full opacity-0;

        .c-radiobutton__input:disabled + & {
            @apply bg-gray-500;
        }
    }

    .c-radiobutton__input:checked + .c-radiobutton__label::after {
        @apply opacity-100;
    }

    .c-radiobutton__icon {
        right: -3rem;
    }

</style>
