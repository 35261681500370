import './styling/main.less';
import Vue from 'vue';

import './core/config';
import { router, initRouter } from './router';
import './project';
import App from './App.vue';
import { routes } from '@/router/routes';
import GTMBasics from './project/tracking/gtm/GTM.basics';
initRouter(routes);

Vue.config.productionTip = false;

const vueDebugEnabled = location.search.includes('$enable-vue-devtools$=true');
if (vueDebugEnabled) {
    Vue.config.devtools = true;
}

// Vue.config.performance = true;

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    render: h => h(App)
});
GTMBasics.init();
