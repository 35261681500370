<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { CreateElement, VNode } from 'vue';
import dictionaryService from '@/core/translation/dictionary.service';
import urlStringHelperService from '@/core/urlStringHelper.service';

export interface TranslationLink {
    name: string;
    url?: string;
    action?: () => void
}

@Component
export default class TranslationWithLinks extends Vue {
    @Prop({ type: String, required: true })
    label!: string;

    @Prop({ type: Array, required: true })
    links!: TranslationLink[];

    get translatedLinks() {
        const links = this.links
            .map(x => ({
                name: dictionaryService.exists(x.name) ? dictionaryService.get(x.name) : x.name,
                url: x.url,
                action: x.action
            } as TranslationLink));
        return links;
    }

    render(createElement: CreateElement) {
        const createSpan = (text: string) => createElement('span', text);
        const createLink = (text: string, url: string) => createElement('a', {
            attrs: {
                href: this.fixupUrl(url),
                target: urlStringHelperService.isExternalScheme(url) ? undefined : '_blank'
            },
            class: 'theme-text-linkcolor hover:underline'
        }, text);
        const createButton = (text: string, action: () => void) => createElement('button', {
            attrs: {
                type: 'button'
            },
            on: {
                click: () => action()
            },
            class: 'theme-text-linkcolor hover:underline'
        }, text);

        const nodes: VNode[] = [];

        const labelArgsSplit = this.label.split(/\{[0-9]\}/);

        for (let i = 0; i < labelArgsSplit.length; ++i) {
            nodes.push(createSpan(labelArgsSplit[i]));

            const translatedLink = this.translatedLinks[i];
            if (translatedLink) {
                if (translatedLink.url) {
                    nodes.push(createLink(translatedLink.name, translatedLink.url));
                } else if (translatedLink.action) {
                    nodes.push(createButton(translatedLink.name, translatedLink.action));
                }
            }
        }

        return createElement('p', nodes);
    }

    fixupUrl(location: string): string {
        if (urlStringHelperService.isExternalScheme(location)) return location;

        return urlStringHelperService.hasCulturePrefix(location) ? location : urlStringHelperService.prependCulturePrefix(location);
    }
}
</script>
