import bus from '@/core/bus';
import { HasOutstandingXhrCallsEventKey } from '@/core/http/constants';
import debounce from 'lodash-es/debounce';

// Set window.prerenderReady to true when all initial XHR requests from header/page has settled

// Debounce for a while to let e.g. loaded products be rendered and start doing XHR requests
const debouncedHandleOutstandingXhrCalls = debounce(handleOutstandingXhrCalls, 1000);

declare global {
    interface Window {
        prerenderReady: boolean
    }
}

window.prerenderReady = false;

bus.on(HasOutstandingXhrCallsEventKey, debouncedHandleOutstandingXhrCalls);

function handleOutstandingXhrCalls(hasOutstandingXhrCalls: boolean) {
    if (window.prerenderReady) {
        bus.off(HasOutstandingXhrCallsEventKey, handleOutstandingXhrCalls);
    }

    if (!hasOutstandingXhrCalls) {
        window.prerenderReady = true;
    }
}
