<template>
    <div v-if="content" class="flex">
        <div v-if="showImage" :class="imageWrapperClass">
            <img v-if="getSmallImage"
                 v-on-error
                 :srcset="`${getSmallImage} 1x, ${getLargeImage} 2x`"
                 :alt="content.displayName"
                 loading="lazy"
                 class="rounded-full employee-image">
            <div v-else
                 class="rounded-full employee-image image-placeholder flex items-center justify-center max-w-full"
                 :style="missingImageStyle">
                <div class="text-white oprema:text-black text-24 font-semi-bold">
                    {{ content.initials }}
                </div>
            </div>
        </div>
        <div class="pr-10 text-13" :class="{ 'pl-20 w-2/3': showImage }">
            <div class="text-15 font-semi-bold" :class="nameLineClass">
                {{ content.displayName }}
            </div>
            <div :class="titleLineClass">
                {{ content.title }}
            </div>
            <div v-if="content.directPhone && !isBot" :class="textLineClass">
                {{ $dictionary.get('Generic.Phone.DirectPhone.ShortLabel') }} <a :href="`tel:${content.directPhone}`" class="" target="_blank">{{ content.directPhone }}</a>
            </div>
            <div v-if="content.mobilePhone && !isBot" :class="textLineClass">
                {{ $dictionary.get('Generic.Phone.MobilePhone.ShortLabel') }} <a :href="`tel:${content.mobilePhone}`" class="" target="_blank">{{ content.mobilePhone }}</a>
            </div>
            <div v-if="showEmail" class="break-all" :class="textLineClass">
                <a :href="`mailto:${content.email}`" class="theme-text-linkcolor" target="_blank">{{ content.email }}</a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { StyleValue } from 'vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import serverContextService from '@/core/serverContext.service';
import { EmployeeViewObject } from '@/types/apiServerContract';
import responsiveProductImageService from '@/project/product/image/responsiveProductImage.service';
import botService from '@/project/site/bot.service';

@Component
export default class EmployeeBlockItem extends Vue {
    @Prop()
    content!: EmployeeViewObject;

    @Prop({ type: Number, default: 0 })
    index!:number;

    @Prop({ type: Boolean, default: true })
    showImagePlaceholderIfEmpty!:number;

    @Prop({ type: String, default: 'mb-5' })
    textLineClass!:string;

    @Prop({ type: String, default: 'mb-5' })
    nameLineClass!:string;

    @Prop({ type: String, default: 'mb-5' })
    titleLineClass!:string;

    @Prop({ type: String, default: 'w-1/3' })
    imageWrapperClass!:string;

    get missingImageStyle(): StyleValue {
        const colors: string[] = !serverContextService.isOprema ? ['#c5d2e0', '#c9c0b7', '#9dbcb0'] : ['#fffef2', '#abd3df', '#ededed'];
        const styles: StyleValue = {
            backgroundColor: colors[this.index % colors.length]
        };
        return styles;
    }

    get getLargeImage(): string | null {
        return this.content && this.content.photoUrl ? responsiveProductImageService.formatImageUrl(this.content.photoUrl, '256') : null;
    }

    get getSmallImage(): string | null {
        return this.content && this.content.photoUrl ? responsiveProductImageService.formatImageUrl(this.content.photoUrl, '128') : null;
    }

    get showEmail(): boolean {
        return !!(this.content.email && !this.isBot);
    }

    get isBot(): boolean {
        return botService.uaIsBot() || botService.uaIsPrerender();
    }

    get showImage():boolean {
        return !!(this.showImagePlaceholderIfEmpty || this.getSmallImage);
    }
}
</script>
<style lang="less" scoped>
.image-placeholder {
}
.employee-image {
    width:12.8rem;
    aspect-ratio: 1;
}
</style>
