<template>
    <header class="z-header absolute top-0 inset-x-0" :class="{'-search-page': isSearchOpen}">
        <ScrollTrigger :options="scrollTriggerOptions">
            <div v-if="showBrands" class="brand-header relative z-1">
                <BrandHeader/>
            </div>
            <div class="top-header-wrapper">
                <div class="top-header relative z-1">
                    <TopHeader/>
                </div>
            </div>
            <div class="main-navigation"
                 :class="{ '-main-navigation-fixed' : mainNavigationFixed,
                           '-transition-enabled' : mainNavigationTransitionEnabled,
                           '-mega-menu-shown': megaMenuShown }">
                <MainNavigation @megaMenuShown="megaMenuShownChange"/>
            </div>
        </ScrollTrigger>

        <ImpersonationBanner/>
    </header>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import ScrollTrigger, { ScrollTriggerOptions } from '@/core/scroll/ScrollTrigger.vue';
import BrandHeader from '@/project/layout/page-header/BrandHeader.vue';
import TopHeader from './TopHeader.vue';
import MainNavigation from './MainNavigation.vue';
import ImpersonationBanner from '@/project/shared/impersonation/ImpersonationBanner.vue';
import themeService from '@/project/app/themes/theme.service';
import authService from '@/core/auth/auth.service';
import appStore from '@/core/app/app.store';
import { PageName } from '@/router/routes/constants';

@Component({
    components: { MainNavigation, TopHeader, BrandHeader, ScrollTrigger, ImpersonationBanner }
})
export default class DesktopPageHeader extends Vue {
    mainNavigationTransitionEnabled = false;
    get mainNavigationFixed() {
        return appStore.desktopMainNavigationFixed;
    }

    get megaMenuShown() {
        return appStore.desktopMegaMenuShown;
    }

    scrollingUp(on: boolean) {
        on ? themeService.restoreBackgroundOpacity() : themeService.disableBackgroundOpacity();
    }

    topHeaderFixed(on: boolean) {
        if (!on) {
            appStore.desktopMainNavigationFixed = on;
            this.mainNavigationTransitionEnabled = false;
        } else {
            themeService.disableBackgroundOpacity();
        }
    }

    megaMenuShownChange(shown: boolean): void {
        appStore.desktopMegaMenuShown = shown;
    }

    mainNavigationHidden(on: boolean) {
        if (on) {
            appStore.desktopMainNavigationFixed = true;
            // Avoid immediate transition when we go to fixed.
            setTimeout(() => this.mainNavigationTransitionEnabled = true, 25);
        }
    }

    get scrollTriggerOptions(): ScrollTriggerOptions {
        return this.showBrands
            ? {
                scrollingUp: { clazz: 'scrolling-up', callback: this.scrollingUp },
                60: { clazz: 'top-header-fixed', callback: this.topHeaderFixed },
                120: this.mainNavigationHidden
            }
            : {
                scrollingUp: { clazz: 'scrolling-up', callback: this.scrollingUp },
                1: { clazz: 'top-header-fixed', callback: this.topHeaderFixed },
                120: this.mainNavigationHidden
            };
    }

    get showBrands(): boolean {
        // not logged in on frontpage: false, else true
        if (this.isOnFrontpage && this.isLoggedIn === false) {
            return false;
        }
        return true;
    }

    get isLoggedIn(): boolean {
        return authService.isLoggedIn();
    }

    get isOnFrontpage(): boolean {
        return appStore.isFrontPage;
    }

    get isSearchOpen(): boolean {
        return this.$route.name === PageName.SEARCH;
    }
}
</script>

<style lang="less" scoped>
    .brand-header, .impersonation {
        height: 6rem;
    }
    :deep(.top-header,.top-header-wrapper) {
        height: 8rem;
        transition: height 200ms ease;

        .top-header-fixed & {
            height: 5.4rem;
        }
    }
    .top-header {
        .top-header-fixed & {
            position: fixed;
            top: 0;
            width: 100%;
        }
    }
    .top-header-wrapper {
        .-search-page & {
            height: 5.4rem;
        }
    }
    .main-navigation {
        .-transition-enabled& {
            transition: transform 200ms ease-in;
        }
        .-main-navigation-fixed& {
            width: 100%;
            top: 5.4rem;
            position: fixed;
            transform: translateY(-100%);
            .scrolling-up &, .-mega-menu-shown& {
                transition: transform 250ms ease-out;
                transform: translateY(0);
                .-search-page & {
                    top: 0;
                    transform: translateY(-100%);
                }
            }
        }
        .-search-page & {
            transform: translateY(-100%);
        }
    }
</style>
