
<template>
    <simple-slider tag="nav" class="h-full">
        <div v-for="(menu, mi) in menus" :key="mi"
             class="navigation-item inline-flex items-center h-full relative mr-20 lg:mr-40"
             :class="{active: megaMenuShown && activeMenu === menu}"
             @mouseenter="mouseEnter(menu)"
             @mouseleave="mouseLeave(menu)">
            <culture-aware-router-link :to="menu.url"
                                       class="navigation-item-link"
                                       @click.native="$emit('closeMegaMenu')">
                {{ menu.name }}
            </culture-aware-router-link>
        </div>

        <div v-if="campaignLink" class="flex-1 flex justify-end">
            <div class="navigation-item inline-flex items-center h-full relative">
                <culture-aware-router-link :to="campaignLink.url"
                                           class="navigation-item-link flex items-center justify-center"
                                           @mouseenter="$emit('closeMegaMenu')">
                    <CampaignIcon :campaign-code="campaignCode" class="mr-8"
                                  :fill="true" :original="false" width="15" height="15"/>

                    <span>{{ campaignLink.name }}</span>
                </culture-aware-router-link>
            </div>
        </div>

        <template #left-arrow="{ active }">
            <div v-if="active" class="prev-arrow" :style="prevNavSliderStyle">
                <CIcon name="arrow-left" width="15" height="15"/>
            </div>
        </template>

        <template #right-arrow="{ active }">
            <div v-if="active" class="next-arrow" :style="nextNavSliderStyle">
                <CIcon name="arrow-right" width="15" height="15"/>
            </div>
        </template>
    </simple-slider>
</template>

<script lang="ts">
import { StyleValue } from 'vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { MenuItem } from '@/types/apiServerContract';
import SimpleSlider from '@/core/ui-components/SimpleSlider.vue';
import serverContextService from '@/core/serverContext.service';
import CampaignIcon from '@/project/product/campaigns/CampaignIcon.vue';
import themeService from '@/project/app/themes/theme.service';

@Component({
    components: { SimpleSlider, CampaignIcon }
})
export default class MainNavigationSlider extends Vue {
    @Prop({ type: Array, required: true })
    menus!: MenuItem[];

    @Prop({ type: Boolean, required: true })
    megaMenuShown!: boolean;

    @Prop({ type: Object, required: false })
    activeMenu!: MenuItem;

    mouseEnter(menu: MenuItem) {
        this.$emit('activeMenuChanged', menu);
    }

    mouseLeave(menu: MenuItem) {
        this.$emit('cancelMegaMenuNavigation', menu);
    }

    get campaignCode() {
        return serverContextService?.settings?.menuCampaignIcon;
    }

    get campaignLink() {
        return serverContextService?.settings?.menuCampaignLink;
    }

    get prevNavSliderStyle(): StyleValue {
        if (themeService.theme?.menuColor) {
            const backgroundGradient = `linear-gradient(90deg, ${themeService.theme.menuColor} 0%, ${themeService.theme.menuColor}00 100%)`;
            return {
                background: backgroundGradient,
                color: themeService.getColor(themeService.theme.menuColor)
            };
        }
        return {};
    }

    get nextNavSliderStyle(): StyleValue {
        if (themeService.theme?.menuColor) {
            const backgroundGradient = `linear-gradient(90deg, ${themeService.theme.menuColor}00 0%, ${themeService.theme.menuColor} 100%)`;
            return {
                background: backgroundGradient,
                color: themeService.getColor(themeService.theme.menuColor)
            };
        }
        return {};
    }
}
</script>

<style lang="less" scoped>

.navigation-item {
    &:after {
        @apply absolute bg-current w-full block bottom-0;
        content: "";
        height: 3px;
        transition: transform 0.3s ease;
        transform: scaleX(0);
    }

    &.active:after, &:hover:after {
        transform: scaleX(1);
    }

    &:last-of-type {
        margin-right: 0;
    }
}

.navigation-item-link {
    font-size: 13px;
    white-space: nowrap;
    user-select: none;
    color: inherit;
    cursor: pointer;
    position: relative;
@apply font-medium;

    &.router-link-active {
    @apply font-bold;
    }
}

.prev-arrow {
    position: absolute;
    left: -10px;

    height: 100%;
    width: 50px;
    background: linear-gradient(90deg, #3062A9 0%, #3062A900 100%);

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.next-arrow {
    position: absolute;
    right: -10px;

    height: 100%;
    width: 50px;
    background: linear-gradient(90deg, #3062A900 0%, #3062A9 100%);

    display: flex;
    justify-content: flex-end;
    align-items: center;
}
body[data-theme="oprema"] {
    .prev-arrow {
        background: linear-gradient(90deg, color-mod(theme('colors.opremayellow.500') a(1)) 0%, color-mod(theme('colors.opremayellow.500') a(0)) 100%);
    }
    .next-arrow {
        background: linear-gradient(90deg, color-mod(theme('colors.opremayellow.500') a(0)) 0%, color-mod(theme('colors.opremayellow.500') a(1)) 100%);
    }
}

/* IE10+ specific styles go here */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .navigation-item {
        &::after {
            transform: scaleX(1);
            transition-property: opacity;
            opacity: 0;
            left: 0;
            right: 0;
        }

        &.active:after, &:hover:after {
            opacity: 1;
        }
    }
}
</style>
