<template>
    <InputCheckbox v-if="$permission.isStaff()"
                   v-model="showPrices"
                   style-switch
                   with-border
                   name="showPrices"
                   :label="$dictionary.get('MyAccount.Settings.CurrentlyShowPrices')"
                   @change="handleClickChange"/>
</template>

<script setup lang="ts">
import { computed, Ref, ref, watch } from 'vue';
import myAccountStore from '@/project/app/client-pages/my-account/my-account.store';

const accountStoreShowPrices = computed(() => { return myAccountStore.showPrices; });
const accountStoreLoaded = computed(() => { return myAccountStore.account?.hidePrices !== undefined; });
const showPrices: Ref<boolean> = ref(false);
watch(accountStoreLoaded, () => {
    showPrices.value = accountStoreShowPrices.value;
});
function handleClickChange() {
    myAccountStore.showPrices = showPrices.value;
}
</script>
