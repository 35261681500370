<template>
    <article v-sync-bodylock-gap class="top-header-container" :style="topHeaderStyle">
        <div class="top-header o-chrome-container h-full">
            <div class="flex items-center h-full relative">
                <CultureAwareRouterLink :to="frontpageUrl" :title="logoAltText">
                    <div :class="{ 'text-white': isInWhiteTheme, 'text-blue-500 oprema:text-black': !isInWhiteTheme }">
                        <template v-if="isOprema">
                            <CIcon name="oprema-logo" original width="150" class="block oprema-logo"/>
                        </template>
                        <template v-else>
                            <CIcon name="logo-half-top" width="63" class="block logo-half-top"/>
                            <CIcon name="logo-half-eet" width="52" class="block logo-half-eet"/>
                        </template>
                    </div>
                </CultureAwareRouterLink>

                <div class="search-left-spacer"></div>

                <SearchCtrl class="search-field mr-auto text-black"
                            section-class="w-full"
                            :section-styling="{}"
                            :show-search-field="true"
                            :anchor-element="searchOverlayAnchorElement"/>

                <div class="header-nav-buttons" :class="{ 'text-white': isInWhiteTheme }">
                    <div v-if="resourceMenu" class="header-nav-button">
                        <button type="button" class="my-account-link with-menu"
                                :class="{ 'text-white': isInWhiteTheme, 'with-menu__active theme-text-linkcolor': showResourcesMenu }"
                                @click="handleClickToggleResourceMenu">
                            <CIcon name="resources" width="20" height="20" :fill="false"
                                   aria-hidden="true"/>
                            <span class="whitespace-nowrap font-medium">{{ $dictionary.get('FrontPage.Resources') }}</span>
                        </button>

                        <transition appear mode="out-in"
                                    enter-active-class="animated zoomIn u-anim-dur-250"
                                    leave-active-class="animated fadeOut u-anim-dur-150">
                            <TopHeaderResourceMenu v-if="showResourcesMenu" @close="closeResourcesMenu"/>
                        </transition>
                        <!-- SEO only - same links as above, but no v-if condition for showing -->
                        <nav class="hidden">
                            <div v-for="(linkGroup, linkGroupIndex) in resourceMenu.resourceMenuItemGroups"
                                 :key="linkGroupIndex">
                                <CultureAwareRouterLink v-for="(link, linkIndex) in linkGroup.links" :key="linkIndex"
                                                        :to="link.url"
                                                        :target="link.target">
                                    {{ link.name }}
                                </CultureAwareRouterLink>
                            </div>
                        </nav>
                    </div>
                    <div v-if="!resourceMenu && productGuidePage" class="header-nav-button">
                        <CultureAwareRouterLink class="my-account-link"
                                                :to="productGuidePage"
                                                :class="{ 'text-white': isInWhiteTheme }">
                            <CIcon name="productguide" width="20" height="20" aria-hidden="true"/>
                            <span class="whitespace-nowrap">{{ $dictionary.get('ProductGuide') }}</span>
                        </CultureAwareRouterLink>
                    </div>

                    <div v-if="salesAndSupportPage" class="header-nav-button">
                        <CultureAwareRouterLink class="my-account-link"
                                                :to="salesAndSupportPage"
                                                :class="{ 'text-white': isInWhiteTheme }">
                            <CIcon name="support" width="20" height="20" aria-hidden="true"/>
                            <span class="whitespace-nowrap">{{ $dictionary.get('FrontPage.SalesAndSupport') }}</span>
                        </CultureAwareRouterLink>
                    </div>

                    <template v-if="isLoggedIn">
                        <div v-if="$permission.canViewFavourites()"
                             class="header-nav-button">
                            <CultureAwareRouterLink class="my-account-link"
                                                    :to="{ name: favouritesPageName }"
                                                    :class="{ 'text-white': isInWhiteTheme }">
                                <CIcon name="favorite-outline" width="20" height="20" aria-hidden="true"/>
                                <span class="whitespace-nowrap">{{ $dictionary.get('MyAccount.Favourites.Title') }}</span>
                            </CultureAwareRouterLink>
                        </div>

                        <div v-if="$permission.canViewMyAccount()"
                             class="header-nav-button">
                            <CultureAwareRouterLink class="my-account-link"
                                                    :to="{ name: myAccountPageName }"
                                                    :class="{ 'text-white': isInWhiteTheme }">
                                <CIcon name="profile" width="20" height="20" aria-hidden="true"/>
                                <span class="whitespace-nowrap">{{ $dictionary.get('MyAccount.Header.Link') }}</span>
                            </CultureAwareRouterLink>
                        </div>
                    </template>

                    <button v-else class="header-nav-button" @click="login">
                        <span class="my-account-link font-bold">
                            <CIcon name="login" width="20" height="20" aria-hidden="true"/>
                            <span class="whitespace-nowrap">{{ $dictionary.get('Authentication.Login.LoginBtn') }}</span>
                        </span>
                    </button>

                    <CultureAwareRouterLink v-if="showBecomeACustomer"
                                            data-tracking-name="becomeACustomerPage"
                                            class="header-nav-button self-start bg-blue-500 oprema:bg-opremablue-500 text-white px-20 py-13 rounded-30"
                                            :to="becomeACustomerPage">
                        <span class="whitespace-nowrap font-regular leading-none">{{ $dictionary.get('Information.BecomeACustomer.HeaderButton') }}</span>
                    </CultureAwareRouterLink>

                    <button v-if="isLoggedIn && isDevMode()" class="header-nav-button" @click="logout">
                        <span class="my-account-link font-bold">
                            <CIcon name="login" width="20" height="20" aria-hidden="true"/>
                            <span class="whitespace-nowrap">{{ $dictionary.get('MyAccount.Navigation.SignOut') }}</span>
                        </span>
                    </button>
                </div>

                <MiniBasket v-if="isLoggedIn && $permission.canPlaceOrder()" class="ml-30"/>
            </div>
        </div>
    </article>
</template>

<script lang="ts">
import Vue, { StyleValue } from 'vue';
import Component from 'vue-class-component';
import authService from '@/core/auth/auth.service';
import SearchCtrl from '@/project/search/SearchCtrl.vue';
import MiniBasket from '@/project/basket/MiniBasket.vue';
import { PageName } from '@/router/routes/constants';
import serverContextService from '@/core/serverContext.service';
import loginFormOverlayService from '@/project/modals/overlays/LoginFormOverlay.service';
import themeService from '@/project/app/themes/theme.service';
import { hexToRgbValues } from '@/core/color/color.service';
import { isDevMode } from '@/core/environmentService';
import { ResourceMenuViewModel } from '@/types/contentServerContract';
const TopHeaderResourceMenu = () => import('@/project/layout/page-header/desktop/TopHeaderResourceMenu.vue');

@Component({
    components: { TopHeaderResourceMenu, MiniBasket, SearchCtrl }
})
export default class TopHeader extends Vue {
    searchTerm = '';
    searchOverlayAnchorElement: Element | null = null;
    showResourcesMenu: boolean = false;

    mounted() {
        this.searchOverlayAnchorElement = this.$el;
    }

    login() {
        loginFormOverlayService.show('login');
    }

    closeResourcesMenu() {
        this.showResourcesMenu = false;
    }

    get resourceMenu(): ResourceMenuViewModel | null {
        return serverContextService.resourceMenu;
    }

    get hasResourceMenu(): boolean {
        return !!((this.resourceMenu?.resourceMenuItemGroups?.length ?? 0) > 0);
    }

    get isLoggedIn(): boolean {
        return authService.isLoggedIn();
    }

    get myAccountPageName() {
        return PageName.MY_ACCOUNT_PROFILE;
    }

    get favouritesPageName() {
        return PageName.MY_ACCOUNT_FAVOURITES;
    }

    get productGuidePage() {
        return serverContextService?.settings?.productGuidePage;
    }

    get becomeACustomerPage() {
        return serverContextService?.settings?.becomeACustomerPage;
    }

    get showBecomeACustomer(): boolean {
        // Only show for users who has never been logged in - and only if page exists
        return ((!this.isLoggedIn && !authService.hasLoggedInBefore()) && !!this.becomeACustomerPage);
    }

    get salesAndSupportPage() {
        return serverContextService?.settings?.salesAndSupportPage;
    }

    get isOprema() {
        return serverContextService?.isOprema;
    }

    isDevMode(): boolean {
        return isDevMode();
    }

    logout() {
        authService.logOff();
    }

    get topHeaderStyle(): StyleValue {
        const rgb = themeService.theme?.topColor && hexToRgbValues(themeService.theme.topColor);

        return {
            ...(rgb && { backgroundColor: `rgba(${rgb.red}, ${rgb.green}, ${rgb.blue}, ${themeService.takeOverOpacity})` }),
            ...(themeService.theme?.topColor && { borderColor: themeService.theme.topColor })
        };
    }

    get isInWhiteTheme() {
        return themeService.isInWhiteTheme;
    }

    get logoAltText():string {
        return serverContextService.footer?.companyDetails?.companyName || '';
    }

    get frontpageUrl():string {
        return serverContextService.siteInfo?.url || '/';
    }

    handleClickToggleResourceMenu() {
        this.showResourcesMenu = !this.showResourcesMenu;
    }
}
</script>

<style lang="less" scoped>
    .top-header-container {
        @apply h-full bg-white text-black;

        transition: background-color 500ms ease-in-out, color 500ms ease-in-out;
    }

    .logo-half-top,.logo-half-eet {
        transition: opacity 250ms ease-in-out, transform 250ms ease-in-out, background-color 500ms ease-in-out, color 500ms ease-in-out;
    }
    .oprema-logo {
        height: 5rem;
        padding-top: 0.6rem;
    }

    .top-header-fixed & {

        .logo-half-eet {
            transform: translateY(-15px);
            opacity: 0;
        }

        .logo-half-top {
            transform: translateY(11px);
        }
    }

    .search-field {
        max-width: 35rem;
        width: 100%;
    }

    .search-left-spacer {
        // Visually align center. Constants set from visually nudging.
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: calc(50vw - 255px);
        max-width: 49rem;
        body[data-theme="oprema"] & {
            min-width: 33rem;
        }
    }

    .header-nav-buttons {
        @apply flex flex-row items-center font-medium;

        .header-nav-button {
            @apply flex flex-col items-center justify-center mr-20;
            &.bg-blue-500 {
                // Add border / outline for use when takeover banner is active
                outline: 0.2rem solid currentColor;
            }

            &:last-child {
                @apply mr-0;
            }

            svg {
                @apply mb-2;
            }

            .my-account-link {
                @apply text-12 flex flex-col items-center justify-center text-center;

                @media (hover) {
                    &:hover {
                        @apply underline;
                    }
                }

                &.router-link-active {
                    @apply font-bold underline;
                }
            }
        }
    }
    .c-page-header-resource-menu {
        a {
            &:focus,
            &:hover {
                span {
                    @apply underline;
                }
            }
        }
    }

    @screen md {
        .header-nav-buttons {
            @apply ml-10;

            .my-account-link.with-menu {
                &:after {
                    @apply absolute block bg-current bottom-0;
                    content: "";
                    height: 0.3rem;
                    transition: transform 0.3s ease;
                    transform: scaleX(0);
                    width: 8rem;
                }

                &.with-menu__active:after, &:hover:after {
                    transform: scaleX(1);
                }
            }
        }
    }

    @screen xl {
        .header-nav-buttons {
            @apply ml-0;
        }
    }
</style>
