<template>
    <div class="c-block-list">
        <BlockRender v-for="(block, ix) in blocks"
                     :id="$anchorFilter.get(block.content.anchorLink)"
                     :key="`${block.alias}-${ix}`"
                     :block="block"
                     :is-first-block="ix === 0"
                     :class="{'o-container' : !(disableContainerForFirstBlock && ix === 0) && enableContainer}"
                     v-bind="$attrs"/>
    </div>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import { JsonContent } from '@/types/contentServerContract';
const props = defineProps({
    blocks: { type: Array<JsonContent>, required: false, default: () => [] },
    enableContainer: { type: Boolean, default: true },
    disableContainerForFirstBlock: { type: Boolean, default: false }
});
const randomIntFromInterval = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

const blockKey = (alias: string, ix: number): string => {
    return `${alias}-${ix}-${randomIntFromInterval(1, 10000)}`;
}

watch(() => props.blocks, () => {
    if (props.blocks?.length) {
        props.blocks.forEach((x, i) => (x as any).key = blockKey(x.alias, i));
    }
}, { immediate: true });
</script>

<style lang="less">
.c-block-list {
    & > * {
        &:not(:last-child) {
            @apply mb-10;
        }

        &.-is-appended-to-prev-block {
            @apply -mt-10;
        }
    }
}

@screen sm {
    .c-block-list {
        & > * {
            &:not(:last-child) {
                @apply mb-20;
            }

            &.-is-appended-to-prev-block {
                @apply -mt-20;
            }
        }
    }
}

@screen md {
    .c-block-list {
        & > * {
            &:not(:last-child) {
                @apply mb-30;
            }

            &.-is-appended-to-prev-block {
                @apply -mt-30;
            }
        }
    }
}
</style>
