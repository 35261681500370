import loggingService from '@/core/logging.service';

function optionalProperty(obj: any, ...args: string[]) {
    const res = args.reduce((obj, level) => obj && obj[level], obj);
    if (args.length === 1 && args[0].indexOf('.') > 0) {
        loggingService.warn({ error: 'Warning: Optional property filter is used with a string argument that properly should have been an array', args });
    }
    return res || '';
}
export default {
    install(Vue) {
        Vue.prototype.$optionalProperty = { get: optionalProperty };
    }
};
