<template>
    <footer v-if="shouldRender">
        <section class="bg-white text-black">
            <div class="o-chrome-container">
                <div class="pt-30 md:pt-40 pb-20 md:pb-30 o-grid">
                    <section v-for="(column, ix) in firstLinkColumns" :key="ix" class="o-grid--item mb-20 lg:mb-0 w-1/2 md:w-1/4 lg:w-2/12">
                        <header class="text-13 md:text-15 font-bold lg:text-18 mb-10">
                            {{ column.title }}
                        </header>

                        <CultureAwareRouterLink v-for="link in column.links" :key="link.url"
                                                :to="link.url"
                                                class="block text-12 md:text-13 lg:text-15 py-5 hover:text-gray-800">
                            <template v-if="link.name === 'Brands'">
                                {{ $dictionary.get('Breadcrumbs.Brands') }}
                            </template>
                            <template v-else-if="link.name === 'Departments'">
                                {{ $dictionary.get('Breadcrumbs.Departments') }}
                            </template>
                            <template v-else>
                                {{ link.name }}
                            </template>
                        </CultureAwareRouterLink>

                        <a v-for="soMeLink in column.socialMediaLinks"
                           :key="soMeLink.url"
                           :href="soMeLink.url"
                           :target="soMeLink.target"
                           class="social-media-link flex flex-row items-center text-12 md:text-13 lg:text-15 py-5 hover:text-gray-800">
                            <img v-if="soMeLink.icon"
                                 :src="soMeLink.icon"
                                 :alt="soMeLink.name"
                                 loading="lazy"
                                 class="mr-8">
                            <span>
                                {{ soMeLink.name }}
                            </span>
                        </a>
                        <StaffTogglePriceShow v-if="ix === 0 && $permission.isStaff()" class="block mt-10"/>
                    </section>
                    <div v-if="salesRep || companyDetails" class="o-grid--item w-full md:w-1/2 lg:w-3/12 lg:ml-auto">
                        <header v-if="!salesRep" class="text-13 md:text-15 font-bold lg:text-18 mb-10">
                            {{ $dictionary.get('Footer.ContactTitle') }}
                        </header>
                        <EmployeeBlockItem
                            v-if="salesRepAsEmployeeViewObject"
                            :content="salesRepAsEmployeeViewObject"
                            :show-image-placeholder-if-empty="false"
                            :name-line-class="'text-13 md:text-15 font-bold lg:text-18 mb-3'"
                            :title-line-class="'mb-22 text-12 md:text-13 lg:text-15'"
                            :text-line-class="'my-10 text-12 md:text-13 lg:text-15'"
                            class="mb-10 w-full"/>
                        <template v-if="companyDetails">
                            <div>
                                <div class="mb-10 font-semi-bold text-12 md:text-13 lg:text-15">
                                    {{ companyDetails.companyName }}
                                </div>
                                <div v-if="companyDetails.phoneNumber" class="text-12 md:text-13  lg:text-15 py-5">
                                    <a :href="`tel:${companyDetails.phoneNumber}`">
                                        {{ companyDetails.phoneNumber }}
                                    </a>
                                </div>
                                <div v-if="companyDetails.email" class="text-12 md:text-13 lg:text-15 py-5">
                                    <a :href="'mailto:' + companyDetails.email" target="_blank" class="underline">
                                        {{ companyDetails.email }}
                                    </a>
                                </div>
                            </div>
                            <div class="mt-20 mb-10 font-semi-bold text-12 md:text-13 lg:text-15">
                                {{ $dictionary.get('Footer.OpeningHoursTitle') }}:
                            </div>
                            <p class="text-12 md:text-13 lg:text-15">
                                {{ companyDetails.openingHours }}
                            </p>
                        </template>
                    </div>
                </div>
            </div>
        </section>
        <section class="address-n-business-unit h-150 md:h-80 py-20 bg-blue-500 oprema:bg-opremayellow-500 text-white oprema:text-black">
            <div class="o-chrome-container h-full flex flex-col md:flex-row md:items-center justify-between text-12 md:text-13">
                <section v-if="companyDetails" class="company-info">
                    <span v-if="companyDetails.companyName" class="font-bold">{{ companyDetails.companyName }}</span>
                    <span v-if="companyDetails.addressInformation">{{ companyDetails.addressInformation }}</span>
                    <span v-if="companyDetails.postalCode || companyDetails.city">{{ companyDetails.postalCode }} {{ companyDetails.city }}</span>
                    <span v-if="companyDetails.vatNumber">{{ $dictionary.get('Footer.VatNo') }} {{ companyDetails.vatNumber }}</span>
                    <span v-if="$dictionary.exists('Footer.GroupLabel')">{{ $dictionary.get('Footer.GroupLabel') }}</span>
                    <span v-if="$dictionary.exists('Footer.GroupLabel2')">{{ $dictionary.get('Footer.GroupLabel2') }}</span>
                </section>
                <section v-if="showLanguageInformation" class="md:whitespace-nowrap" @click="activateSitePicker">
                    <span class="text-13 font-bold mr-15">{{ CountryAndLanguageLabel }}</span>
                    <CallToAction class="cta--small cta--white">
                        {{ $dictionary.get('Footer.ChangeCountryAndLanguage') }}
                    </CallToAction>
                </section>
            </div>
        </section>
        <script v-if="companyDetailsSchemaJson"
                type="application/ld+json"
                v-html="companyDetailsSchemaJson"/>
        <script v-if="breadcrumbsSchemaJson"
                type="application/ld+json"
                v-html="breadcrumbsSchemaJson"/>
    </footer>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { BreadcrumbItem } from '@/types/contentServerContract';
import authService from '@/core/auth/auth.service';
import siteService from '@/project/site/site.service';
import dictionaryService from '@/core/translation/dictionary.service';
import { EmployeeViewObject, SalesRepViewObject } from '@/types/apiServerContract';
import Api from '@/project/http/Api.service';
import appStore from '@/core/app/app.store';
import { SHOW_SITE_PICKER } from '@/project/modals/overlays/SitePickerOverlay.service';
import bus from '@/core/bus';
import serverContextService from '@/core/serverContext.service';
import StaffTogglePriceShow from '@/project/app/client-pages/my-account/StaffTogglePriceShow.vue';

import EmployeeBlockItem from '@/project/app/blocks/information/EmployeeBlockItem.vue';

const salesRep = ref<SalesRepViewObject | null>(null);

const companyDetails = computed(() => serverContextService.footer?.companyDetails || null);

const firstLinkColumns = computed(() => serverContextService?.footer?.sitemapColumns ?? []);

const isLoggedIn = computed(() => authService.isLoggedIn());

const countryCode = computed(() => siteService.getCountryCode());

const siteOptions = computed(() => siteService.getCountryOptions());

const showLanguageInformation = computed(() => {
    return siteOptions.value?.countries.length && (siteOptions.value.countries.length > 1 || siteOptions.value.countries[0].sites.length > 1);
});

const CountryAndLanguageLabel = computed(() => {
    const lang = siteService.getLanguageCode() || '';
    const countryCode = siteService.getCountryCode() || '';
    return `${dictionaryService.countryNameByCountryCode(countryCode)} / ${dictionaryService.languageNameByLanguageCode(lang)}`;
});

const salesRepAsEmployeeViewObject = computed<EmployeeViewObject | null>(() => {
    if (!salesRep.value) return null;
    return {
        photoUrl: salesRep.value.picture,
        mobilePhone: salesRep.value.mobilePhoneNo,
        directPhone: salesRep.value.directPhoneNo || '',
        displayName: salesRep.value.name || '',
        title: salesRep.value.title || '',
        email: salesRep.value.email || '',
        initials: '',
        id: ''
    };
});

const shouldRender = computed(() => !appStore.isTunnelView);

const isOprema = computed(() => serverContextService?.isOprema);

const companyDetailsSchemaJson = computed(() => {
    const socialMediaLinks = firstLinkColumns.value.filter(c => c.socialMediaLinks?.length).map(some => some.socialMediaLinks.map(link => link.url));
    const schema: any = {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        url: `${document.location.origin}${window.servercontext.url}`,
        logo: `${window.servercontext.siteAssetsUrl}/favicon.svg`,
        foundingDate: isOprema.value ? '2010' : '1986',
        name: companyDetails.value?.companyName
    };
    if (companyDetails.value?.email) {
        schema.email = companyDetails.value?.email;
    }
    if (companyDetails.value?.phoneNumber) {
        schema.telephone = companyDetails.value?.phoneNumber;
    }
    if (companyDetails.value?.phoneNumber || companyDetails.value?.email) {
        schema.contactPoint = {
            '@type': 'ContactPoint',
            telephone: companyDetails.value?.phoneNumber,
            email: companyDetails.value?.email
        };
    }
    if (companyDetails.value?.city && companyDetails.value.addressInformation && companyDetails.value.postalCode) {
        schema.address = {
            '@type': 'PostalAddress',
            addressLocality: companyDetails.value?.city,
            streetAddress: companyDetails.value?.addressInformation,
            postalCode: companyDetails.value?.postalCode
        };
    }
    if (socialMediaLinks.length) {
        schema.sameAs = socialMediaLinks.flat(1) || [];
    }
    return JSON.stringify(schema);
});

const breadcrumbsSchemaJson = computed(() => {
    const jsonldobj: any = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: []
    };
    appStore.breadcrumbPrettified.map((item: BreadcrumbItem, index) => {
        jsonldobj.itemListElement.push({
            '@type': 'ListItem',
            position: (index + 1),
            name: item.name,
            item: item.url ? `${document.location.origin}${window.servercontext.url}${item.url}` : window.location.href
        } as any);
    });
    return JSON.stringify(jsonldobj);
});

watch(isLoggedIn, (newVal) => {
    if (newVal) {
        getSalesRep();
    } else {
        salesRep.value = null;
    }
}, { immediate: true });

watch(countryCode, () => {
    if (isLoggedIn.value) {
        getSalesRep();
    } else {
        salesRep.value = null;
    }
});

function activateSitePicker() {
    bus.emit(SHOW_SITE_PICKER);
}

async function getSalesRep(): Promise<void> {
    const response = await Api.user.salesRep();
    const salesRepIsValid = checkIfSalesrepContainsData(response.salesRep);
    salesRep.value = salesRepIsValid ? response.salesRep! : null;
}

function checkIfSalesrepContainsData(rep?: SalesRepViewObject) {
    return !!rep && Object.values(rep).some(x => !!x);
}
</script>

<style lang="less" scoped>
.address-n-site {
    height: 15.6rem;
}

.company-info {
    span {
        display: block;
        line-height: 20px;
    }
}

@screen md {
    .address-n-site {
        height: 8rem;
    }

    .company-info {
        @apply flex flex-wrap;

        span:not(:last-child) {
            &:after {
                content: '•';
                font-weight: 500;
                margin: 0 1rem;
            }
        }
    }
}

.social-media-link {
    img {
        width: 18px;
        height: 18px;
    }
}
</style>
