<template>
    <component :is="tag" class="simple-slider">
        <div ref="container" class="simple-slider-container">
            <div ref="content" class="simple-slider-content" :style="style">
                <slot/>
            </div>
        </div>

        <div class="nav-arrows">
            <div @click="prev">
                <slot name="left-arrow" :active="leftOverflow">
                    <div v-if="leftOverflow" class="prev-arrow">
                        <CIcon name="arrow-left" width="15" height="15"/>
                    </div>
                </slot>
            </div>
            <div @click="next">
                <slot name="right-arrow" :active="rightOverflow">
                    <div v-if="rightOverflow" class="next-arrow">
                        <CIcon name="arrow-right" width="15" height="15"/>
                    </div>
                </slot>
            </div>
        </div>
    </component>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import breakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';
import { StyleValue } from 'vue';

type OverflowLocation = 'left' | 'right' | 'both' | 'none';

@Component
export default class SimpleSlider extends Vue {
    @Prop({ type: String, required: false, default: 'div' }) tag!: string;
    @Prop({ type: Number, required: false, default: 0 }) slidesToScroll!: number;
    @Prop({ type: Number, required: false, default: 500 }) speed!: number;
    @Prop({ type: Boolean, required: false, default: false }) spaceBetween!: boolean;

    overflowLoc: OverflowLocation = 'none';
    translateX: number = 0;
    isScrolling: boolean = false;
    observer: any = null;

    get style(): StyleValue {
        return {
            transition: `transform ${this.speed}ms ease-in-out`,
            transform: `translate(${this.translateX}px, 0)`,
            ...(this.spaceBetween && { justifyContent: 'space-between' })
        };
    }

    get leftOverflow(): boolean {
        return this.overflowLoc === 'left' || this.overflowLoc === 'both';
    }

    get rightOverflow(): boolean {
        return this.overflowLoc === 'right' || this.overflowLoc === 'both';
    }

    prev() {
        if (this.isScrolling) return;

        this.isScrolling = true;

        const containerMetrics = this.$refs.container.getBoundingClientRect();
        const contentMetrics = this.$refs.content.getBoundingClientRect();

        const overflowingElements = this.getOverflowingElements('left');

        let transformX = 0;

        if (this.slidesToScroll > 0) {
            const lastElement = overflowingElements[overflowingElements.length - 1];
            const lastElementMetrics = lastElement.getBoundingClientRect();
            const currentOffSet = containerMetrics.left - contentMetrics.left;
            transformX = (containerMetrics.left - currentOffSet) - lastElementMetrics.left;
        } else { // Slide till prev page
            const containerWidth = containerMetrics.width;

            let lastElement: Element | null = null;
            let currentWidth = 0;

            for (const element of overflowingElements) {
                if ((currentWidth += element.scrollWidth) > containerWidth) break;
                lastElement = element;
            }

            if (lastElement) {
                const lastElementMetrics = lastElement.getBoundingClientRect();
                const currentOffSet = containerMetrics.left - contentMetrics.left;
                transformX = (containerMetrics.left - currentOffSet) - lastElementMetrics.left;
            }
        }

        this.translateX = transformX;
    }

    next() {
        if (this.isScrolling) return;

        this.isScrolling = true;

        const containerMetrics = this.$refs.container.getBoundingClientRect();
        const contentMetrics = this.$refs.content.getBoundingClientRect();

        const overflowingElements = this.getOverflowingElements('right');

        let transformX = 0;

        if (this.slidesToScroll > 0) {
            const lastElement = overflowingElements[overflowingElements.length - 1];
            const lastElementMetrics = lastElement.getBoundingClientRect();
            const currentOffSet = containerMetrics.left - contentMetrics.left;
            transformX = (containerMetrics.right - currentOffSet) - lastElementMetrics.right;
        } else { // Slide till next page
            const containerWidth = containerMetrics.width;

            let lastElement: Element | null = null;
            let currentWidth = 0;

            for (const element of overflowingElements) {
                if ((currentWidth += element.scrollWidth) > containerWidth) break;
                lastElement = element;
            }

            if (lastElement) {
                const lastElementMetrics = lastElement.getBoundingClientRect();
                const currentOffSet = containerMetrics.left - contentMetrics.left;
                transformX = (containerMetrics.right - currentOffSet) - lastElementMetrics.right;
            }
        }

        this.translateX = transformX;
    }

    getOverflowingElements(direction: OverflowLocation): Element[] {
        const overflowingElements: Element[] = [];
        const elements = direction === 'right' ? Array.from(this.$refs.content.children) : Array.from(this.$refs.content.children).reverse();

        for (const element of elements) {
            if (this.slidesToScroll > 0 && (overflowingElements.length + 1 > this.slidesToScroll)) break;

            const overflowLocation = this.determineOverflow(element, this.$refs.container);
            if (overflowLocation === direction) {
                overflowingElements.push(element);
            }
        }

        return overflowingElements;
    }

    determineOverflow(content: Element, container: Element): OverflowLocation {
        if (!content || !container) return 'none';

        const containerMetrics = container.getBoundingClientRect();
        const containerMetricsRight = Math.floor(containerMetrics.right);
        const containerMetricsLeft = Math.floor(containerMetrics.left);
        const contentMetrics = content.getBoundingClientRect();
        const contentMetricsRight = Math.floor(contentMetrics.right);
        const contentMetricsLeft = Math.floor(contentMetrics.left);
        if (containerMetricsLeft > contentMetricsLeft && containerMetricsRight < contentMetricsRight) {
            return 'both';
        } else if (contentMetricsLeft < containerMetricsLeft) {
            return 'left';
        } else if (contentMetricsRight > containerMetricsRight) {
            return 'right';
        } else {
            return 'none';
        }
    }

    setOverflowLoc() {
        const lOverflow = this.getOverflowingElements('left').length > 0;
        const rOverflow = this.getOverflowingElements('right').length > 0;
        this.overflowLoc = lOverflow && rOverflow ? 'both' : lOverflow ? 'left' : rOverflow ? 'right' : 'none';
    }

    transitionEnded() {
        this.setOverflowLoc();
        this.isScrolling = false;
    }

    get activeBreakpoint() {
        return breakpointsState.activeBreakpoint;
    }

    @Watch('activeBreakpoint')
    onBreakpointChange() {
        this.setOverflowLoc();
    }

    mounted() {
        this.$refs.content.addEventListener('transitionend', this.transitionEnded);

        this.observer = new MutationObserver(() => {
            this.setOverflowLoc();
        });

        // Setup the observer
        this.observer.observe(
            this.$refs.content,
            { childList: true, subtree: true }
        );

        this.$nextTick(() => {
            this.setOverflowLoc();
        });
    }

    beforeDestroy() {
        this.$refs.content.removeEventListener('transitionend', this.transitionEnded);

        if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }
    }

    $refs!: Vue['$refs'] & {
        container: HTMLElement,
        content: HTMLElement
    }
}
</script>

<style lang="less" scoped>
.simple-slider {
    position: relative;

    height: 100%;
    width: 100%;
}

.simple-slider-container {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    font-size: 0;
    height: 100%;
    width: 100%;

    display: flex;
}

.simple-slider-content {
    position: relative;
    height: 100%;
    width: 100%;

    display: flex;
}

.prev-arrow,
.next-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.prev-arrow {
    left: -10px;
}

.next-arrow {
    right: -10px;
}

.prev-arrow .disabled,
.next-arrow .disabled {
    display: none;
}
</style>
