<template>
    <ValidationProvider v-slot="{ errors, classes, validate }"
                        ref="provider"
                        :rules="rules"
                        tag="div"
                        :name="name">
        <div class="relative w-full h-50" :class="{ hasValue: labelText }">
            <input :id="id"
                   v-prohibit-zoom
                   class="c-input-file__input h-50 w-full z-0 bg-gray-300 text-black outline-none"
                   :class="[classes, { 'border border-red-500': invalid }]"
                   type="file"
                   :placeholder="placeholder"
                   v-bind="$attrs"
                   @change="changedInput($event, validate($event))"
                   v-on="listeners">
            <input :id="`${id}-hidden`"
                   v-model="value"
                   v-autofocus="autofocus"
                   :aria-labelledby="`${id}-label`"
                   type="text"
                   class="h-0 w-0"
                   v-bind="$attrs"
                   v-on="listenersForFakeField"
                   @input="changedFakeInput">
            <label v-if="labelText" :id="`${id}-label`" :for="id"
                   class="c-input-file__label w-full truncate y-center absolute left-0 text-gray-800 text-13 font-medium leading-none">
                {{ labelText }}
            </label>

            <v-popover v-if="infoLabel"
                       class="info-input-icon"
                       popover-wrapper-class="wrapper flex flex-col items-end"
                       popover-inner-class="tooltip-inner whitespace-normal max-w-1/2 p-10 text-center"
                       trigger="click" auto-hide offset="5" placement="top">
                <button type="button">
                    <span class="sr-only">{{ $dictionary.get('Checkout.Address.Info') }}</span>
                    <CIcon name="info"/>
                </button>

                <template #popover>
                    {{ infoLabel }}
                </template>
            </v-popover>
        </div>
        <template v-if="showErrors">
            <ol>
                <li v-for="error in errors" :key="error" class="text-red-500 text-11 font-medium px-18 pb-5 pt-3">
                    {{ error }}
                </li>
            </ol>
        </template>
    </ValidationProvider>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class InputFile extends Vue {
    @Prop({ required: true })
    value!: string;

    @Prop({ type: String, required: false, default: '' })
    label!: string;

    @Prop({ type: String, default: '' })
    infoLabel!: string;

    @Prop({ type: String, required: true })
    name!: string;

    @Prop({ type: [String, Object], required: false, default: '' })
    rules!: string | object;

    @Prop({ type: Boolean, required: false, default: false })
    autofocus!: boolean;

    @Prop({ type: Boolean, required: false, default: true })
    showErrors!: boolean;

    @Prop({ type: String, required: false, default: '' })
    placeholder!: string;

    @Prop({ type: Boolean, required: false, default: false })
    invalid!: boolean;

    $refs!: {
        provider: any
    }

    get id() {
        return this.name + Math.random();
    }

    get listeners() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { input, change, ...listeners } = this.$listeners;
        return listeners;
    }

    get listenersForFakeField() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { input, ...listeners } = this.$listeners;
        return listeners;
    }

    get labelText(): string {
        return this.isRequired ? `${this.label} *` : this.label;
    }

    get isRequired(): boolean {
        return 'required' in this.$attrs ||
            (typeof this.rules === 'string' && this.rules.includes('required')) ||
            (typeof this.rules === 'object' && !!(this.rules as any).required);
    }

    async changedInput(event, validationProviderValidate) {
        const validationResult = await validationProviderValidate;
        console.log('fileInput', event);
        if (validationResult.valid) {
            const fileList = event.target.files;
            const files: unknown[] = [];
            Array
                .from(Array(fileList.length).keys())
                .map(x => {
                    files.push({ file: fileList[x], fileName: fileList[x].name });
                });
            this.$emit('change', files);
        } else {
            console.log('invalid file!', event, validationResult);
        }
    }

    changedFakeInput(event) {
        console.log('fake field input', event.target.value);
        this.$emit('input', event.target.value);
    }
}
</script>

<style lang="less" scoped>
    .c-input-file__input {
        @apply rounded-8 min-w-0 shadow-input text-12 px-18;
        transition: background-color ease-out 0.3s;

        .hasValue & {
            outline: 0;
            @apply pt-18;

            & ~ .c-input-file__label {
                transform: translate(0, -100%) scale(0.8);
                @apply pt-0 pb-4;
            }
        }

        &.invalid {
            @apply border border-solid border-red-500 text-red-500;
        }

        &[disabled] {
            @apply text-gray-700;
        }
        &::file-selector-button {
            --hover-active-color: hsl(215, 55.8%, 36.125%);
            border: .2rem solid theme('colors.blue.500');
            padding: .2rem .4rem;
            margin: 0.3rem .4rem 0 0;
            border-radius: .2rem;
            background-color: theme('colors.blue.500');
            color: theme('colors.white');
            transition: 1s;
            line-height: 1;
            font-size: 1rem;
            box-sizing: border-box;

            &:active,&:hover {
                background-color: var(--hover-active-color) !important;

                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    background-color: grey !important;
                }
            }
        }

        .c-input--white & {
            @apply bg-white;

            &:focus {
                @apply bg-white;
            }
        }

        &:focus {
            @apply bg-gray-250;

            & ~ .c-input-file__label {
                @apply text-gray-700;
            }
        }

        &::-ms-reveal {
            display: none;
        }
    }

    .c-input-file__label {
        @apply cursor-text text-12;
        transition: transform 0.3s ease-in-out, color ease-out 0.3s;
        transform-origin: left;
        left: 1.8rem;
        width: calc(100% - 4rem);
        line-height: 1.1; // So letters like lowercase "g" are not cut off
        letter-spacing: 0.02rem;
    }
    .info-input-icon {
        @apply absolute inset-y-0 flex items-center justify-center;
        right: 15px;
    }

    .info-input-icon button {
        @apply flex items-center justify-center select-none cursor-pointer;
        opacity: .75;
        transition: opacity 200ms ease-in-out;

        @media (hover) {
            &:focus-within,
            &:hover {
                opacity: 1;
            }
        }
    }
    body[data-theme="oprema"] {
        .c-input-file__input {
            &::file-selector-button {
                background-color: theme('colors.opremablue.500');
                border: .2rem solid theme('colors.opremablue.500');
            }
        }
    }
</style>
