<template>
    <div v-if="isImpersonating" class="impersonation z-header fixed top-0 left-0 right-0 bg-red-300">
        <div class="impersonation bg-red-300 text-white flex items-center o-chrome-container">
            <span>{{ $dictionary.get('MyAccount.Impersonate.CurrentlyImpersonating') }}</span>
            <span class="ml-5 font-semi-bold">{{ impersonateeName }}</span>
            <CallToAction class="ml-auto"
                          :text="$dictionary.get('MyAccount.Impersonate.EndImpersonateBtn')"
                          @click="endImpersonation"/>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import impersonationStore from '@/project/shared/impersonation/impersonation.service';

const isImpersonating = computed(() => impersonationStore.isImpersonating);
const impersonateeName = computed(() => impersonationStore.impersonateeName);

function endImpersonation() {
    impersonationStore.endImpersonation();
}
</script>

<style lang="less" scoped>
.impersonation {
    height: 6rem;
}
</style>
