<template>
    <div v-link-click-tracker :class="{ '--hide-prices': !showPrices }">
        <div class="flex flex-col bg-gray-300">
            <div class="flex flex-col min-h-screen">
                <PageHeader/>
                <main class="main w-full min-h-screen"
                      :class="{'tunnel-view': isTunnelView, 'take-over': isTakeOverEnabled, 'no-mobile-brands': hideMobileBrands, 'search-page': isSearchPage }">
                    <div v-if="showBreadcrumb" class="o-chrome-container pt-10 min-h-60">
                        <BreadCrumb/>
                    </div>
                    <transition appear mode="out-in"
                                enter-active-class="animated fadeIn u-anim-dur-250"
                                leave-active-class="animated fadeOut u-anim-dur-150">
                        <RouterView/>
                    </transition>
                </main>
                <PageFooter class="mt-40"/>
            </div>
        </div>

        <ErrorToaster/>
        <ModalsContainer/>
        <MyAccountModule/>
        <SitePickerModule/>
        <AuthenticationModule/>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';
import PageHeader from '@/project/layout/page-header/PageHeader.vue';
import PageFooter from '@/project/layout/page-footer/PageFooter.vue';
import BreadCrumb from '@/project/layout/breadcrumb/Breadcrumb.vue';
import appStore from '@/core/app/app.store';
import ErrorToaster from '@/core/messages/ErrorToaster.vue';
import MyAccountModule from '@/project/app/client-pages/my-account/MyAccountModule.vue';
import ModalsContainer from '@/project/modals/ModalsContainer.vue';
import SitePickerModule from '@/project/site/SitePickerModule.vue';
import AuthenticationModule from '@/project/auth/AuthenticationModule.vue';
import themeService from '@/project/app/themes/theme.service';
import authService from '@/core/auth/auth.service';
import maintenancePageOverlayService from '@/project/modals/overlays/MaintenancePageOverlay.service';
import cookieService from '@/core/cookie.service';
import siteService from '@/project/site/site.service';
import { PageName } from '@/router/routes/constants';
import myAccountStore from '@/project/app/client-pages/my-account/my-account.store';

@Component({
    components: {
        ErrorToaster,
        PageHeader,
        PageFooter,
        BreadCrumb,
        ModalsContainer,
        SitePickerModule,
        AuthenticationModule,
        MyAccountModule
    }
})
export default class App extends Vue {
    pageTypeName: string = '';
    get isTunnelView(): boolean {
        return appStore.isTunnelView;
    }

    get isTakeOverEnabled() {
        return themeService.isTakeOverEnabledAndLoaded;
    }

    get hideMobileBrands(): boolean {
        // not logged in and on frontpage: true, else false
        if (this.isOnFrontpage && this.isLoggedIn === false) {
            return true;
        }
        return false;
    }

    get isLoggedIn(): boolean {
        return authService.isLoggedIn();
    }

    get isOnFrontpage(): boolean {
        return appStore.isFrontPage;
    }

    get isInMaintenanceMode(): boolean {
        return appStore.isMaintenanceMode;
    }

    @Watch('isInMaintenanceMode', { immediate: true })
    isInMaintenanceModeChange(isInMaintenanceMode: boolean) {
        if (isInMaintenanceMode) {
            maintenancePageOverlayService.show();
        }
    }

    get isProductDetailsPage(): boolean {
        return this.pageTypeName === PageName.PRODUCT_DETAILS;
    }

    get isSearchPage(): boolean {
        return this.pageTypeName === PageName.SEARCH;
    }

    get isProductListPage(): boolean {
        return this.pageTypeName === PageName.CATEGORY || this.pageTypeName === PageName.CATEGORY_2;
    }

    get showBreadcrumb(): boolean {
        return !appStore.hideBreadcrumb;
    }

    get showPrices(): boolean {
        return myAccountStore.showPrices;
    }

    @Watch('$route', { immediate: true })
    onRouteChange() {
        this.pageTypeName = this.$router.currentRoute.name || '';
    }

    mounted() {
        cookieService.set({ name: 'latestSiteVisited', value: siteService.getCulture() || undefined, exdays: 180, domain: location.hostname, path: '/' });
    }
}
</script>

<style lang="less" scoped>

.main {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    // IE11 sticky-footer trick. This and flex on outer.
    flex: 1 0 auto;
    margin-top: 10rem;

    transition: margin 500ms ease-in-out;
    &.no-mobile-brands {
        margin-top: 12rem;
    }
}

@screen md {
    .main {
        margin-top: 20rem;
        &.no-mobile-brands:not(.take-over) {
            margin-top: 14rem;
        }
        &.search-page:not(.take-over) {
            margin-top: 14rem;
        }

        &.take-over {
            margin-top: 0;
        }
    }
}

.main.tunnel-view {
    margin-top: 0;
}

</style>
