<template>
    <ValidationProvider
        v-slot="{ errors }"
        tag="span"
        :rules="rules"
        :name="name || label">
        <input
            :id="id"
            v-model="modelValue"
            class="c-checkbox__input"
            type="checkbox"
            :name="name"
            :value="theValue"
            :checked="shouldBeChecked"
            v-bind="$attrs"
            v-on="listeners"
            @input="updateValue($event, errors)">
        <label class="c-checkbox__label" :for="id" :class="{
            'with-border': withBorder,
            'solid-background':solidBackground,
            'c-checkbox__label-switch':styleSwitch }">
            <div v-if="styleSwitch" class="c-checkbox__label-switch-dot"/>
            <CIcon v-if="!styleSwitch" name="checkmark" class="checkmark"/>
            <div class="my-5 ml-15" :class="{'sr-only': srOnly}">
                <span v-if="label && !links" class="select-none" v-html="label">
                </span>
                <TranslationWithLinks v-else-if="label && links" :label="label" :links="links"/>
                <span v-else-if="htmlLabel" v-html="htmlLabel"/>
            </div>
        </label>
        <template v-if="showErrors">
            <ol>
                <li v-for="error in errors" :key="error" class="text-red-500 text-11 font-medium pr-20 pb-5 pt-3">
                    {{ error }}
                </li>
            </ol>
        </template>
    </ValidationProvider>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import TranslationWithLinks, { TranslationLink } from '@/core/translation/translation-with-links.vue';
/*
Inspired by this: https://www.smashingmagazine.com/2017/08/creating-custom-inputs-vue-js/
 */
@Component({
    model: {
        prop: 'modelValue',
        event: 'change'
    },
    components: { TranslationWithLinks }
})
export default class InputCheckbox extends Vue {
    @Prop({ type: String, default: '' })
    label!: string;

    @Prop(String)
    htmlLabel!: string;

    @Prop(String)
    name!: string;

    @Prop({ type: Array })
    links!: TranslationLink[];

    @Prop({ default: true })
    theValue;

    @Prop({ default: true })
    trueValue;

    @Prop({ default: false })
    falseValue;

    @Prop({ default: false })
    modelValue!: boolean | Array<string>;

    @Prop({
        type: [Object, String],
        default: ''
    })
    rules!: string | object;

    @Prop({ type: Boolean, required: false, default: true })
    showErrors!: boolean;

    @Prop({ type: Boolean, default: false })
    withBorder!: boolean;

    @Prop({ type: Boolean, default: false })
    srOnly!: boolean;

    @Prop({ type: Boolean, default: false })
    styleSwitch!: boolean; // Shows "swipe" iOS style checkbox

    @Prop({ type: Boolean, default: false })
    solidBackground!: boolean;

    internalValue: string | object | number | null = null;

    get id(): string {
        return this.name + Math.random();
    }

    updateValue(event, errors) {
        const isChecked = event.target.checked;

        if (this.modelValue instanceof Array) {
            const newValue: any = [...this.modelValue];

            if (isChecked) {
                newValue.push(this.theValue);
            } else {
                newValue.splice(newValue.indexOf(this.theValue), 1);
            }

            // this.$emit('change', newValue);
            this.$emit('change', newValue);
        } else {
            // this.$emit('change', isChecked ? this.trueValue : this.falseValue);
            this.$emit('change', isChecked ? this.trueValue : this.falseValue);
        }
    }

    get shouldBeChecked() {
        if (this.modelValue instanceof Array) {
            return this.modelValue.includes(this.theValue);
        }
        // Note that `true-value` and `false-value` are camelCase in the JS
        return this.modelValue === this.trueValue;
    }

    get listeners() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { change, ...listeners } = this.$listeners;
        return listeners;
    }

    created() {
        this.internalValue = this.theValue;
    }
}
</script>

<style lang="less" scoped>
.c-checkbox {
@apply flex;
}

.c-checkbox__input {
@apply sr-only;

    &:focus ~ .c-checkbox__label::before{
        border: 1px solid theme('colors.gray.700');
    }
}

.c-checkbox__label {
@apply relative inline-flex items-center cursor-pointer select-none;

    .checkmark {
    @apply absolute text-blue-500 oprema:text-opremablue-500 opacity-0;
        width: 1.6rem;
        height: 1.2rem;
        left: 1.2rem;
        top: calc(50% - 0.7rem);
        transform: translateX(-50%);
    }

    .c-checkbox__input:disabled + & {
    @apply text-gray-700 cursor-default;

        .checkmark {
        @apply text-gray-700;
        }
    }
}

.c-checkbox__label.solid-background::before {
@apply bg-gray-300;
}

.c-checkbox__label.with-border::before {
    border: 0.1rem solid;
@apply border-gray-800;
}

.c-checkbox__label::before {
@apply bg-white flex-shrink-0;
    content: '';
    width: 2rem;
    height: 2rem;

    .background-gray & {
    @apply bg-gray-400;
    }
}

.c-checkbox__input:checked + .c-checkbox__label .checkmark
{
    opacity: 1;
    border: 0 none;
}
@sizeSwitch: 2.4rem;
@sizeSwitchWidth: 4rem;
@sizeSwitchDot: @sizeSwitch - .4rem;
.c-checkbox__label-switch {
    &::before {
    @apply bg-gray-400;
        width: @sizeSwitchWidth;
        height: @sizeSwitch;
        border-radius: @sizeSwitch @sizeSwitch;
        border: 0.2rem transparent solid;
    }
    .c-checkbox__label-switch-dot {
    @apply bg-white;
        content: '';
        display: block;
        width: @sizeSwitchDot;
        height: @sizeSwitchDot;
        border-radius: 50%;
        pointer-events: none;
        border: 0.2rem transparent solid;
        transition: all ease-in-out 100ms;
        box-shadow: 0 0 2rem #985B4917;
        position: absolute;
        left: .2rem;
        outline: 1px solid theme('colors.gray.500');
    }
}
.c-checkbox__input:checked + .c-checkbox__label.c-checkbox__label-switch {
    &::before {
    }
    .c-checkbox__label-switch-dot {
    @apply bg-blue-500 oprema:bg-opremablue-500;
        left: @sizeSwitchWidth - @sizeSwitchDot - .2rem;
    }
}

</style>
