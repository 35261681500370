<template>
    <div v-if="campaignIconUrl" class="campaign-image">
        <img v-on-error :src="campaignIconUrl" alt="campaign-icon">
    </div>
    <CIcon v-else-if="campaignCode" :name="campaignCode" v-bind="$attrs"/>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import Api from '@/project/http/Api.service';

const CLEARANCE_CODE = 'clearance';

const props = defineProps({
    campaignCode: {
        type: String,
        required: true
    }
});

const campaignIconUrl = ref<string | null>(null);

async function getCampaignWithIcon() {
    // This icon is a local SVG
    if (!props.campaignCode || props.campaignCode.toLowerCase() === CLEARANCE_CODE) return;

    try {
        const campaign = await Api.product.getCampaignWithIcon(props.campaignCode);
        campaignIconUrl.value = campaign.campaignIconUrl ?? null;
    } catch {
        // not found
    }
}

onMounted(() => {
    getCampaignWithIcon();
});
</script>

<style lang="less" scoped>
.campaign-image {
    height: 25px;
    width: 25px;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: auto;
        width: auto;
        max-height: 100%;
        max-width: 100%;
        filter: brightness(0) invert(1);
    }
}
</style>
