<template>
    <ResponsiveIcon v-if="image"
                    :image-url="image.url"
                    :width="image.width"
                    :height="image.height"
                    :alt-text="altText || image.name"/>
    <div v-else/>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Media } from '@/types/contentServerContract';
import ResponsiveIcon from '@/core/responsive/image/ResponsiveIcon.vue';
@Component({
    name: 'ResponsiveIconWrap',
    components: {
        ResponsiveIcon
    }
})
export default class ResponsiveIconWrap extends Vue {
    @Prop()
    image!:Media;

    @Prop() altText!:string;
}
</script>
