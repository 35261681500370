<template>
    <div class="w-full">
        <transition-group name="list-fade" class="w-full overflow-y-hidden overflow-x-hidden h-23 whitespace-nowrap" tag="ul" itemscope
                          itemtype="https://schema.org/BreadcrumbList">
            <li v-for="(crumb, index) in breadcrumbs" :key="crumb.name + index" itemprop="itemListElement" itemscope
                itemtype="https://schema.org/ListItem"
                class="-no-wrap inline-block">
                <template v-if="index < breadcrumbs.length - 1">
                    <CultureAwareRouterLink :to="{ path: crumb.url, name: crumb.page }" class="font-medium text-11 text-gray-900 underline hover:text-gray-800" itemprop="item">
                        <span itemprop="name">{{ crumb.name }}</span>
                        <meta itemprop="position" :content="(index+1).toString()">
                    </CultureAwareRouterLink>
                    <CIcon
                        class="mx-6"
                        name="arrow-right"
                        width="6"
                        height="6"/>
                </template>
                <span v-else class="text-11 text-gray-900">
                    <span itemprop="name">{{ crumb.name }}</span>
                    <meta itemprop="position" :content="(index+1).toString()">
                </span>
            </li>
        </transition-group>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import appStore from '@/core/app/app.store';
import { BreadcrumbItem } from '@/types/contentServerContract';

@Component
export default class Breadcrumb extends Vue {
    get breadcrumbs(): BreadcrumbItem[] {
        const crumbs = appStore?.breadcrumbPrettified ? [...appStore.breadcrumbPrettified] : [];
        return crumbs;
    }
}
</script>

<style lang="less" scoped>
.list-fade-enter-active,
.list-fade-leave-active {
    transition: all 0.2s;
}

.list-fade-enter-active {
    transition-delay: 0.2s;
}

.list-fade-enter,
.list-fade-leave-to {
    opacity: 0;
}

.list-fade-enter {
    transition-delay: 0.2s;
}

.-no-wrap {
    white-space: nowrap;
}
</style>
