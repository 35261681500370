import Api from '@/project/http/Api.service';
import { MenuItem } from '@/types/apiServerContract';
import { ExtendedMenuItem } from '@/project/layout/page-header/mobile/MobileMenu.vue';

class MegaMenuService {
    private mappedBreadcrumbs: Map<string, ExtendedMenuItem[] | undefined> | undefined;
    private megaMenuPromise = Api.navigation.megaMenu()
        .then(megaMenu => {
            return Object.freeze(megaMenu.menu);
        });

    async getMegaMenu(): Promise<readonly MenuItem[]> {
        return this.megaMenuPromise;
    }

    mapBreadcrumbs(menus: readonly MenuItem[]): Map<string, MenuItem[]> {
        const map = new Map<string, MenuItem[]>();
        for (const menu of menus) {
            this.mapBreadcrumbForChild(map, menu, [menu], 1);
        }
        return map;
    }

    async getUrlForMenuItem(categoryId: string) {
        const absoluteId = categoryId.replace(/\D+/g, '');
        const megaMenu = await this.getMegaMenu();
        const menu = megaMenu.find(x => x.id === absoluteId);
        if (menu) {
            return menu.url;
        }

        return null;
    }

    mapBreadcrumbForChild(map: Map<string, MenuItem[]>, menu: MenuItem, breadcrumb: MenuItem[], level: number) {
        map.set(menu.id, breadcrumb);
        menu.children.forEach(m => {
            this.mapBreadcrumbForChild(map, m, [...breadcrumb, m], level + 1);
        });
    }

    async getBreadCrumbForCategory(categoryId: string): Promise<ExtendedMenuItem[]> {
        const megaMenu = await this.getMegaMenu();
        if (!this.mappedBreadcrumbs) {
            this.mappedBreadcrumbs = this.mapBreadcrumbs(megaMenu);
        }
        return this.mappedBreadcrumbs.get(categoryId) || [];
    }

    getMappedBreadCrumbForCategory(categoryId: string): ExtendedMenuItem[] {
        return this.mappedBreadcrumbs?.get(categoryId) || [];
    }

    async getCategoryById(categoryId: string): Promise<ExtendedMenuItem> {
        const breadcrumb = await this.getBreadCrumbForCategory(categoryId);
        return breadcrumb[breadcrumb.length - 1];
    }
}

export default new MegaMenuService();
