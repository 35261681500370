<template>
    <ValidationProvider v-slot="{ errors, classes }" :rules="rules" tag="div" :name="name"
                        :custom-messages="customMessages">
        <div class="relative w-full" :class="{ hasValue: !!value }">
            <textarea :id="id"
                      v-autofocus="autofocus"
                      class="c-input-textarea__input h-full w-full z-0 bg-gray-300 text-black outline-none"
                      :class="[classes, inputClasses]"
                      :value="value"
                      :rows="rows"
                      cols="10"
                      v-bind="$attrs"
                      @input="changedInput"
                      v-on="listeners">
                      </textarea>
            <label v-if="labelText" :for="id"
                   class="c-input-textarea__label w-full truncate absolute currentColor text-gray-800 font-medium leading-none">
                {{ labelText }}
            </label>
            <div v-if="maxCounter && errors.length === 0" class="text-12 font-medium px-18 pb-5 pt-3">
                {{ maxCounter }}
            </div>
        </div>
        <template v-if="showErrors && errors && errors.length">
            <ol>
                <li v-for="error in errors" :key="error" class="text-red-500 text-12 font-medium px-18 pb-5 pt-3">
                    {{ error }}
                </li>
            </ol>
        </template>
    </ValidationProvider>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import dictionaryService from '@/core/translation/dictionary.service';

@Component
export default class InputTextArea extends Vue {
    @Prop({ required: true })
    value!: string;

    @Prop({ type: String, required: false, default: '' })
    label!: string;

    @Prop({ type: String, required: true })
    name!: string;

    @Prop({ type: [String, Object], required: false, default: '' })
    rules!: string | object;

    @Prop({ type: Boolean, required: false, default: false })
    autofocus!: boolean;

    @Prop({ type: Boolean, required: false, default: true })
    showErrors!: boolean;

    @Prop({ type: Boolean, required: false, default: false })
    showMaxCounter!: boolean;

    @Prop({ type: Number, required: false, default: 5 })
    rows!: number;

    @Prop({ type: String, required: false, default: 'resize-none' })
    inputClasses!: string;

    @Prop()
    customMessages!: object;

    get id() {
        return this.name + Math.random();
    }

    get listeners() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { input, ...listeners } = this.$listeners;
        return listeners;
    }

    get hasValue(): boolean {
        return this.value !== '' && this.value != null;
    }

    get labelText(): string {
        return this.isRequired ? `${this.label} *` : this.label;
    }

    get isRequired(): boolean {
        return 'required' in this.$attrs ||
            (typeof this.rules === 'string' && this.rules.includes('required')) ||
            (typeof this.rules === 'object' && !!(this.rules as any).required);
    }

    changedInput(event) {
        this.$emit('input', event.target.value);
    }

    get maxCounter(): null | string {
        if (this.showMaxCounter && this.rules && Object.keys(this.rules).includes('max')) {
            // @ts-ignore
            const max = this.rules.max as number;
            const valueLength = this.value?.length ?? 0;
            return dictionaryService.get('Validation.TextAreaCharsLeftCounter', [(max - valueLength).toString()]);
        }
        return null;
    }
}
</script>

<style lang="less" scoped>
    .c-input-textarea__input {
        @apply rounded-8 min-w-0 shadow-input text-16 px-18 py-20 leading-normal placeholder-gray-825;
        transition: background-color ease-out 0.3s;
        vertical-align: middle;

        .hasValue & {
            outline: 0;
            padding-top: 1.7rem;

            & ~ .c-input-textarea__label {
                transform: translate(0, -100%) scale(0.8);
                padding-top: 1.1rem;
                padding-bottom: 0;
                top: 1.9rem;
            }
        }

        &.invalid {
            @apply border border-solid border-red-500 text-red-500;
        }

        &[disabled] {
            @apply text-gray-700;
        }

        .c-input--white & {
            @apply bg-white;

            &:focus {
                @apply bg-white;
            }
        }

        &:focus {
            @apply bg-gray-250;

            & ~ .c-input-textarea__label {
                @apply text-gray-825;
            }
        }
    }

    .c-input-textarea__label {
        @apply cursor-text text-12;
        transition: transform 0.3s ease-in-out, color ease-out 0.3s;
        transform-origin: left;
        left: 1.8rem;
        top: 1.5rem;
        width: calc(100% - 4rem);
        line-height: 1.2; // So letters like lowercase "g" are not cut off
        letter-spacing: 0.02rem;
    }
    @screen md {
        .c-input-textarea__input {
            @apply text-12;
        }
    }
</style>
