<template>
    <img v-if="imageUrl"
         v-on-error
         class="responsive-icon"
         :src="imageSrc"
         :srcset="imageSrcSets || undefined"
         :width="width || undefined"
         :height="height || undefined"
         :alt="altText"
         v-bind="$attrs">
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Component({
    name: 'ResponsiveIcon'
})
export default class ResponsiveIcon extends Vue {
    @Prop({ type: String, default: '' })
    imageUrl!:string;

    @Prop() altText!:string;

    @Prop({ type: Number, default: 0 })
    height!: number;

    // Number or e.g. 'auto'
    @Prop({ type: Number, default: 0 })
    width!: number;

    @Prop({ type: String, default: 'transparent' })
    bgColor!: string;

    @Prop({ type: Boolean, default: false }) allowUpscale!:boolean;

    @Prop({ type: String, default: 'pad', validator: value => ['crop', 'pad', 'max'].includes(value as string) })
    mode!: string;

    get imageSrc() {
        if (!(this.imageUrl)) return undefined;
        return this.imageSrcFormat?.svg || this.imageSrcFormat?.x1 || undefined;
    }

    get imageSrcSets() {
        return this.imageSrcFormat?.x1 ? `${this.imageSrcFormat.x1} 1x, ${this.imageSrcFormat.x2} 2x` : null;
    }

    get imageSrcFormat() {
        if (!(this.imageUrl)) return undefined;
        if (this.imageUrl.endsWith('.svg')) {
            return {
                svg: this.imageUrl
            };
        }
        let bgColor = this.normalizedBgColor;
        if (bgColor) {
            bgColor = bgColor === 'transparent' ? '' : `&bgcolor=${bgColor}`;
        }
        const upscale = `&upscale=${this.allowUpscale}`;
        const mode = `&rmode=${this.mode}`;
        return {
            x1: `${this.imageUrl}?width=${this.width / 2}&height=${this.height / 2}${bgColor}${upscale}${mode}`,
            x2: `${this.imageUrl}?width=${this.width}&height=${this.height}${bgColor}${upscale}${mode}`
        };
    }

    get normalizedBgColor(): string {
        return this.bgColor ? this.bgColor.replace('#', '') : '';
    }
}
</script>

<style lang="less">
.responsive-icon {
    &.c-on-error {
        min-width: 8rem;
    }
}
</style>
