<template>
    <Breakpoints v-if="!isTunnelView">
        <MobilePageHeader slot="max-sm"/>
        <DesktopPageHeader slot="min-md"/>
    </Breakpoints>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import appStore from '@/core/app/app.store';
import MobilePageHeader from '@/project/layout/page-header/mobile/MobilePageHeader.vue';
import DesktopPageHeader from '@/project/layout/page-header/desktop/DesktopPageHeader.vue';

@Component({
    components: { DesktopPageHeader, MobilePageHeader }
})
export default class PageHeader extends Vue {
    get isTunnelView(): boolean {
        return appStore.isTunnelView;
    }
}
</script>
