<template>
    <ValidationProvider v-slot="{ errors, classes }" :rules="rules" tag="div" :name="name"
                        :custom-messages="customMessages">
        <div class="c-input-select relative w-full h-50" :class="[variant, { 'hasLabelPrefix': labelPrefix, hasValue: hasValue || labelPrefix }]">
            <div v-if="labelPrefix" class="c-input-select__label-prefix">
                {{ labelPrefix }}
            </div>
            <select
                :id="id"
                v-model="internalValue"
                v-prohibit-zoom
                class="c-input-select__select w-full z-0"
                :class="[showErrors ? classes : '', selectClasses]"
                :name="name"
                v-bind="$attrs"
                v-on="listeners">
                <slot/>
            </select>
            <CIcon name="arrow-down" class="c-input-select__arrow"/>
        </div>
        <div v-if="showErrors && errors && errors.length">
            <div v-for="error in errors" :key="error" class="text-red-500 text-12 font-medium px-18 pb-5 pt-5">
                {{ error }}
            </div>
        </div>
    </ValidationProvider>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class InputSelect extends Vue {
    internalValue: string | object | number | null = null;

    @Prop({ required: true })
    value!: string | object | number | null;

    @Prop({ type: String, required: true })
    name!: string;

    @Prop({ type: [String, Object], required: false, default: '' })
    rules!: string | object;

    @Prop({ type: Boolean, required: false, default: true })
    showErrors!: boolean;

    @Prop({ type: String, required: false })
    selectClasses!: string;

    @Prop({ type: String, required: false })
    labelPrefix!: string;

    @Prop({ type: String, validator: value => ['blue', 'white'].includes(value as string) })
    variant!: 'blue' | 'white';

    @Prop()
    customMessages!: object;

    get id() {
        return this.name + new Date().valueOf();
    }

    created() {
        this.internalValue = this.value;
    }

    @Watch('value')
    onValueChange(value: object) {
        this.internalValue = value;
    }

    @Watch('internalValue')
    onInternalValueChange(value: any) {
        this.$emit('input', value);
    }

    get listeners() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { input, ...listeners } = this.$listeners;
        return listeners;
    }

    get hasValue(): boolean {
        return !!this.value;
    }
}
</script>

<style lang="less" scoped>
    .c-input-select {
        @apply bg-gray-300 relative rounded-8;

        &:after {
            content: '';
            @apply absolute;
            top: 0;
            right: 0.6rem;
            bottom: 0;
            margin: auto 0;
            width: 4rem;
            height: calc(100% - 2rem);
            pointer-events: none;
        }

        .c-input-select__label {
            cursor: text;
        }
        &.hasValue .c-input-select__label {
            transform: translate(0, -100%) scale(0.8);
            padding-top: 1.9rem;
            padding-bottom: 0;
        }

        .c-input-select__select {
            @apply rounded-8 absolute inset-0 bg-transparent shadow-input pl-18 pr-25;
            -webkit-appearance: none;

            &::-ms-expand {
                display: none;
            }
            .hasValue.hasLabelPrefix& {
                @apply text-12;
                outline: 0;
                padding-top: 1.7rem;
            }

            &.invalid {
                @apply border border-solid border-red-500 text-red-500;
            }

            :deep(option) {
                @apply text-black;

                &.c-input-select__select-option-invalid,
                &[disabled] {
                    @apply cursor-default text-gray-700;
                }
            }

            &[disabled] {
                @apply cursor-default text-gray-700;
                :deep(option) {
                    @apply text-gray-700;
                    cursor: not-allowed;
                }
                + .c-input-select__arrow {
                    @apply text-gray-700;
                }
            }
        }

        .c-input--blue &,
        &.blue {
            @apply bg-blue-500 text-white;

            .c-input-select__select {
                @apply text-white;
            }
        }

        .c-input--white &,
        &.white {
            @apply bg-white text-black;

            .c-input-select__select {
                @apply text-black;
            }
        }

        .c-input-select__arrow {
            pointer-events: none;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 1.6rem;
            z-index: 1;
            margin: auto 0;
            width: 1.3rem;
        }
        .c-input-select__label-prefix {
            @apply inline-block text-12 text-gray-800;
            padding-left: 1rem;
            padding-right: 1.8rem;
            padding-top: .6rem;
            transform: scale(0.8);
        }
    }

</style>
