<template>
    <div>
        <div :class="sectionClass"
             :style="searchWrapperStyle">
            <div class="">
                <div class="u-trans u-trans-height" :class="{'h-40 md:h-0': !isSearchOverlayScrolledDown, 'h-0 md:h-0': isSearchOverlayScrolledDown}"/>
                <div class="h-60 flex items-center md:hidden w-full">
                    <CultureAwareRouterLink :to="frontpageUrl" :title="logoAltText" @click.native="clickCloseMobileSearch">
                        <div :class="{ 'text-white': isInWhiteTheme, 'text-blue-500 oprema:text-black': !isInWhiteTheme }">
                            <template v-if="isOprema">
                                <CIcon name="oprema-logo" original width="150" class="block oprema-logo"/>
                            </template>
                            <template v-else>
                                <CIcon name="logo-half-top" width="60" class="block logo-half-top"/>
                                <CIcon name="logo-half-eet" width="52" class="block logo-half-eet"/>
                            </template>
                        </div>
                    </CultureAwareRouterLink>
                    <button type="button"
                            class="ml-auto py-5 pr-5"
                            :class="{ 'text-white': isInWhiteTheme, 'theme-text-linkcolor': !isInWhiteTheme }"
                            @click="clickCloseMobileSearch">
                        <CIcon name="close" width="18" height="18"/>
                        <span class="sr-only">{{ $dictionary.get('Generic.Close') }}</span>
                    </button>
                </div>
                <div class="md:hidden text-center font-semi-bold text-15 pb-8" :class="{ 'text-white': isInWhiteTheme }">
                    {{ $dictionary.get('Search.MobileSearchFieldHeader') }}
                </div>
                <section class="input-search relative w-full md:w-auto">
                    <input
                        ref="inputElem"
                        v-prohibit-zoom
                        maxlength="250"
                        name="search"
                        type="search"
                        class="input-field h-full w-full px-20 text-blue-500 oprema:text-black placeholder-blue-500 oprema:placeholder-black text-12 rounded-full border"
                        :style="searchInputStyle"
                        :placeholder="$dictionary.get('Search.SearchFieldPlaceholder')"
                        @input="lowLevelInputHandler($event)">
                    <transition mode="out-in"
                                enter-active-class="animated fadeIn u-anim-dur-200"
                                leave-active-class="animated fadeOut u-anim-dur-200">
                        <button v-if="term" key="reset" class="reset-icon" type="button"
                                @click="reset">
                            <CIcon name="close" :color="searchInputIconColor"/>
                            <span class="sr-only">{{ $dictionary.get('Generic.Back') }}</span>
                        </button>
                        <CIcon v-else key="search" name="search" class="search-icon"
                               :color="searchInputIconColor"/>
                    </transition>
                </section>
                <div class="u-trans u-trans-height" :class="{'h-40 md:h-0': !isSearchOverlayScrolledDown, 'h-10 md:h-0': isSearchOverlayScrolledDown}"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue, { CSSProperties, StyleValue } from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import debounce from 'lodash-es/debounce';
import urlHelper from '@/core/urlHelper.service';
import { Route } from 'vue-router';
import themeService from '@/project/app/themes/theme.service';
import serverContextService from '@/core/serverContext.service';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwind from '@/styling/tailwind.config';
const tailwindConfiguration = resolveConfig(tailwind);

const minNoOfCharacters = 2;
const debounceTime = 300;
@Component
export default class SearchCtrl extends Vue {
    term = '';
    debounceSetTerm = debounce(this.setTerm, debounceTime);
    showOverlay = false;
    typing = false;
    isSearchOverlayScrolledDown: boolean = false;

    @Prop({ type: HTMLElement })
    anchorElement!: HTMLElement;

    @Prop({ type: Boolean, default: false })
    showSearchField!: boolean;

    @Prop({ type: Object, required: false })
    sectionStyling!: StyleValue;

    @Prop({ type: String, default: '' })
    sectionClass!: string;

    @Watch('$route')
    onRouteChange(route: Route, oldRoute: Route) {
        if (route.path !== oldRoute?.path) {
            this.$emit('closeMobileSearch');
        }
        if (!this.showOverlay) {
            const term = urlHelper.getTerm();
            if (!term) {
                this.clickCloseMobileSearch();
                return;
            } else {
                this.onTermChanged(term);
                this.openMobileSearch();
            }
        }

        if (!urlHelper.getTerm()) {
            // We came to page without term - reset / close
            this.setTerm('', false);
        } else {
            // Term updated - for instance from translation (alternative) search
            this.setSyncedTerm(urlHelper.getTerm()!);
            this.openMobileSearch();
        }
    }

    @Watch('term')
    onTermChanged(term: string) {
        this.typing = true;

        this.debounceSetTerm(term);
    }

    @Watch('showSearchField')
    onShowSearchFieldChanged(showSearchField: boolean, oldShowSearchField: boolean) {
        if (showSearchField) {
            this.focusSearchInputField();
        }
    }

    setTerm(term: string, goBack: boolean = true) {
        this.setSyncedTerm(term);
        const oldShow = this.showOverlay;
        this.showOverlay = this.shouldShowOverlay(term);
        const justShown = this.showOverlay && !oldShow;

        if (this.showOverlay) {
            urlHelper.setTerm(term, justShown);
            if (!justShown) {
                urlHelper.clearFacets();
            }
        }
        this.typing = false;
    }

    reset() {
        this.setTerm('');
    }

    clickCloseMobileSearch() {
        urlHelper.setTerm('');
        this.$emit('closeMobileSearch');
    }

    openMobileSearch() {
        this.$emit('openMobileSearch');
        this.isSearchOverlayScrolledDown = false;
    }

    focusSearchInputField() {
        // To focus input field on iOS, here comes a hack:
        // create invisible dummy input to receive the focus first
        const fakeInput = document.createElement('input');
        fakeInput.setAttribute('type', 'text');
        fakeInput.style.position = 'absolute'; // hide fake input field
        fakeInput.style.opacity = '0';
        fakeInput.style.height = '0';
        fakeInput.style.fontSize = '16px'; // disable auto zoom
        document.body.prepend(fakeInput);
        // focus so that subsequent async focus will work
        fakeInput.focus();

        setTimeout(() => {
            // now we can focus on the target input
            const targetInput = this.$refs.inputElem;
            if (targetInput) {
                targetInput.style.fontSize = '16px'; // disable auto zoom
                targetInput.focus();
                targetInput.style.fontSize = 'inherit'; // reset font size
            }
            // cleanup
            fakeInput.remove();
        }, 300);
    }

    shouldShowOverlay(term: string): boolean {
        return term?.length >= minNoOfCharacters;
    }

    setSyncedTerm(term: string = '') {
        this.term = term;
        this.$refs.inputElem.value = term;
    }

    lowLevelInputHandler(event) {
        const term = event.target.value;
        if (term.length > 0 && term.trim().length === 0) {
            this.$refs.inputElem.value = '';
        } else {
            this.term = term;
        }
    }

    $refs!: Vue['$refs'] & {
        inputElem: HTMLInputElement
    }

    mounted() {
        this.showOverlay = this.shouldShowOverlay(this.term);
        this.setSyncedTerm(urlHelper.getTerm());
    }

    get searchInputIconColor(): string {
        return themeService.isInWhiteTheme ? 'white' : this.isOprema ? 'black' : (tailwindConfiguration.theme.colors.blue['500']);
    }

    get isOprema() {
        return serverContextService?.isOprema;
    }

    get searchInputStyle(): StyleValue {
        const isInWhiteTheme = themeService.isInWhiteTheme;

        return {
            ...(isInWhiteTheme && { color: '#fff' }),
            ...(isInWhiteTheme && { backgroundColor: 'transparent' }),
            ...(isInWhiteTheme && { borderColor: '#fff' })
        };
    }

    get searchWrapperStyle(): StyleValue {
        return {
            ...(this.sectionStyling as CSSProperties || {}),
            transform: `translateY(${this.showSearchField ? '0' : '-200px'})`
        };
    }

    get isInWhiteTheme() {
        return themeService.isInWhiteTheme;
    }

    get logoAltText():string {
        return serverContextService.footer?.companyDetails?.companyName || '';
    }

    get frontpageUrl():string {
        return serverContextService.siteInfo?.url || '/';
    }
}

</script>

<style lang="less" scoped>
    .input-search {
        transition: height 200ms ease;
        height: 4rem;

        .top-header-fixed & {
            height: 3.2rem;
        }
    }

    .input-field {
        padding-right: 30px;

        &:focus,
        &.touched {
            outline: 0;
        }

        &.border {
            @apply border-blue-500 border-2;
        }

        &::placeholder {
            color: inherit;
            opacity: 0.8;
        }
    }

    .reset-icon, .search-icon {
        position: absolute;
    }

    .reset-icon {
        right: 19px;
        top: 15px;
        height: 11px;
        width: 11px;

        svg {
            @apply absolute top-0 left-0 h-full w-full;
        }

        .top-header-fixed & {
            top: 11px;
        }

        &:after {
            @import (reference) '../../styling/1-Tools/extend-clickable-area';
            .extend-clickable-area()
        }
    }

    .search-icon {
        @apply text-blue-500;
        right: 16px;
        top: 13px;
        height: 15px;
        width: 15px;

        .top-header-fixed & {
            top: 9px;
        }
    }
    body[data-theme="oprema"] {
        .input-field {
            padding-bottom: 0.1rem;

            &.border {
                @apply border-black;
            }
        }
        .search-icon {
            @apply text-black;
        }
    }
</style>
