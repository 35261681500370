<template>
    <component :is="resolvedBlock"
               v-if="block"
               :content="block.content"
               :alias="block.alias"
               v-bind="$attrs"/>
</template>

<script setup lang="ts">
import {computed, PropType} from 'vue';
import {JsonContent} from '@/types/contentServerContract';
import {blockResolver, ClientComponentType} from '@/core/app/componentResolver.service';

const props = defineProps({
    block: { type: Object as PropType<JsonContent>, required: true }
});

const resolvedBlock: ClientComponentType | undefined = computed(() => {
    return blockResolver.resolve(props.block.alias)?.component;
});

</script>
